import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import * as Linking from "expo-linking";

import UnverifiedUserHeader from "../components/UnverifiedUserHeader";
import VerifyEmailScreen from "../screens/VerifyEmailScreen";

import { UnverifiedUserScreenParamList } from "./types";

export const UnverifiedUserLinkingConfiguration = {
  prefixes: [Linking.makeUrl("/")],
  config: {
    screens: {
      VerifyEmailScreen: "verify-email",
    },
  },
};

const UnverifiedUserStack = createStackNavigator<UnverifiedUserScreenParamList>();

const UnverifiedUserNavigator = () => {
  return (
    <UnverifiedUserStack.Navigator
      initialRouteName={"VerifyEmailScreen"}
      screenOptions={{
        headerTitle: () => <UnverifiedUserHeader />,
        headerLeft: () => null,
        cardStyle: { backgroundColor: "#fff" },
        animationEnabled: false,
      }}
    >
      <UnverifiedUserStack.Screen
        name="VerifyEmailScreen"
        component={VerifyEmailScreen}
      />
    </UnverifiedUserStack.Navigator>
  );
};

export default UnverifiedUserNavigator;
