import { Ionicons } from "@expo/vector-icons";
import {
  ResizeMode,
  Video,
  AVPlaybackNativeSource,
  VideoReadyForDisplayEvent,
} from "expo-av";
import { HStack, IconButton, Spacer } from "native-base";
import React, { useState } from "react";
import { View, ImageBackground, LayoutChangeEvent } from "react-native";

interface ResponsiveImageProps {
  source: AVPlaybackNativeSource;
  resizeMode: ResizeMode;
  fileIndex: number;
  numFiles: number;
  onClose: () => void;
  onForwards: () => void;
  onBackwards: () => void;
}

const ResponsiveVideoBackground: React.FC<ResponsiveImageProps> = ({
  source,
  resizeMode,
  fileIndex,
  numFiles,
  onClose,
  onForwards,
  onBackwards,
}) => {
  const [viewSize, setViewSize] = useState<{
    height: number;
    width: number;
  } | null>(null);
  const [videoSize, setVideoSize] = useState<{
    height: number;
    width: number;
  } | null>(null);
  const [showVideo, setShowVideo] = useState(false);

  const handleLayoutChange = (e: LayoutChangeEvent) => {
    const { height, width } = e.nativeEvent.layout;
    setViewSize({ height: height - 1, width: width - 1 });
    setShowVideo(true);
  };

  return (
    <View
      onLayout={handleLayoutChange}
      style={{
        backgroundColor: "black",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <HStack h="32px">
        <Spacer />
        <IconButton
          onPress={onClose}
          icon={<Ionicons name="close" size={25} color="white" />}
        />
      </HStack>
      <Spacer />
      {viewSize && showVideo ? (
        <Video
          source={source}
          resizeMode={resizeMode}
          style={{ width: "100%", height: "100%", maxHeight: viewSize.height - 64 }}
          useNativeControls
        />
      ) : (
        <></>
      )}
      <Spacer />
      <HStack h="32px">
        {fileIndex > 0 ? (
          <IconButton
            onPress={onBackwards}
            icon={<Ionicons name="arrow-back" size={25} color="white" />}
          />
        ) : (
          <></>
        )}
        <Spacer />
        {fileIndex < numFiles - 1 ? (
          <IconButton
            onPress={onForwards}
            icon={<Ionicons name="arrow-forward" size={25} color="white" />}
          />
        ) : (
          <></>
        )}
      </HStack>
    </View>
  );
};

export default ResponsiveVideoBackground;
