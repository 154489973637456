import { createStackNavigator } from "@react-navigation/stack";
import React from "react";

import SupportScreen from "../../screens/SupportScreen";
import { fontFamily } from "../../theme";
import { SupportParamList } from "../types";

const SupportStack = createStackNavigator<SupportParamList>();

const AccountNavigator: React.FC = () => {
  return (
    <SupportStack.Navigator
      screenOptions={{
        cardStyle: { backgroundColor: "white" },
        headerTitleStyle: { fontFamily },
      }}
    >
      <SupportStack.Screen
        name="SupportScreen"
        component={SupportScreen}
        options={{ headerTitle: "Support" }}
      />
    </SupportStack.Navigator>
  );
};

export default AccountNavigator;
