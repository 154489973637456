import { Checkbox, Divider, HStack, Spacer, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { FieldValues, UseFormWatch } from "react-hook-form";
import { TouchableOpacity } from "react-native";

export interface DaySelectorState {
  MONDAY: boolean;
  TUESDAY: boolean;
  WEDNESDAY: boolean;
  THURSDAY: boolean;
  FRIDAY: boolean;
  SATURDAY: boolean;
  SUNDAY: boolean;
}

export const defaultDaySelectorState: DaySelectorState = {
  MONDAY: false,
  TUESDAY: false,
  WEDNESDAY: false,
  THURSDAY: false,
  FRIDAY: false,
  SATURDAY: false,
  SUNDAY: false,
};

export const daysOfTheWeek: (keyof DaySelectorState)[] = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
];

export const getDayString = (date: Date) => {
  switch (date.getDay()) {
    case 1:
      return "MONDAY";
    case 2:
      return "TUESDAY";
    case 3:
      return "WEDNESDAY";
    case 4:
      return "THURSDAY";
    case 5:
      return "FRIDAY";
    case 6:
      return "SATURDAY";
    case 0:
      return "SUNDAY";
    default:
      throw Error("Error");
  }
};

export interface DaySelectorProps {
  value: DaySelectorState;
  onChange: (values: any) => any;
  watch?: UseFormWatch<FieldValues>;
}

const DaySelector: React.FC<DaySelectorProps> = ({
  value,
  onChange,
  watch,
}) => {
  let selectedDate: Date | null;
  if (watch) {
    selectedDate = watch("initialDeadlineDate", new Date());
  } else {
    selectedDate = null;
  }
  const [valueState, setValueState] = useState<DaySelectorState>(value);

  // Update state on deadline change
  useEffect(() => {
    if (selectedDate) {
      const date = selectedDate;
      setValueState((prevValueState) => ({
        ...prevValueState,
        [getDayString(date)]: true,
      }));
    }
  }, [selectedDate]);

  // Call onChange when the state changes
  useEffect(() => {
    onChange(valueState);
  }, [valueState]);

  // Update state on box check
  const handleChange = (dayString: string, checked: boolean) => {
    if (selectedDate && dayString !== getDayString(selectedDate)) {
      setValueState({ ...valueState, [dayString]: checked });
    } else if (!selectedDate) {
      setValueState({ ...valueState, [dayString]: checked });
    }
  };

  return (
    <HStack space={2} divider={<Divider orientation="vertical" />}>
      {daysOfTheWeek.map((dayString) => {
        return (
          <HStack key={dayString} space={1}>
            <VStack alignItems="center">
              <Text>{dayString[0]}</Text>
              <Checkbox
                value={dayString}
                size="sm"
                accessibilityLabel={dayString.toLowerCase()}
                isChecked={valueState[dayString]}
                onChange={(value) => handleChange(dayString, value)}
              />
            </VStack>
          </HStack>
        );
      })}
    </HStack>
  );
};

export default DaySelector;
