import * as React from "react";
import {
  Box,
  Heading,
  Text,
  ScrollView,
  HStack,
  Spacer,
  IconButton,
  FlatList,
  VStack,
} from "native-base";
import { RefreshControl, TouchableOpacity } from "react-native";
import {
  RouteProp,
  useIsFocused,
  useNavigation,
  useRoute,
} from "@react-navigation/native";

import UserBody from "../components/UserBody";
import { useCommitment, useCommitmentInstances } from "../lib/hooks";
import { CommitmentsParamList } from "../navigation/types";
import {
  getHumanizedRepeatDescription,
  getHumanizedPunishmentDescription,
  getHumanizedStatusSummary,
  commitmentIsDeleted,
  commitmentIsUpdated,
  calculateRemainingSkipAllowance,
  nowOrInitialDeadline,
} from "../lib/utils";
import { Feather, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import {
  CommitmentStatus,
  PunishmentStatus,
  CommitmentRead,
} from "../lib/types";
import { useState } from "react";
import { useAnalytics } from "../components/AnalyticsProvider";

export default function CommitmentDetailsScreen() {
  const navigation = useNavigation();
  const route =
    useRoute<RouteProp<CommitmentsParamList, "CommitmentDetailsScreen">>();
  const isFocused = useIsFocused();
  const commitmentObj = useCommitment(route.params.id, isFocused);
  const commitment = commitmentObj.commitment;
  const instancesObj = useCommitmentInstances(
    route.params.entity_id,
    isFocused
  );
  const instances = instancesObj.instances;
  const [scrollRefreshing, setScrollRefreshing] = useState(false);
  const analytics = useAnalytics();
  
  const handleRefresh = async () => {
    setScrollRefreshing(true);
    const p1 = commitmentObj.refresh();
    const p2 = instancesObj.refresh();
    await p1;
    await p2;
    setScrollRefreshing(false);
  };

  if (commitment !== null) {
    return (
      <UserBody h="100%">
        <FlatList
          flex={1}
          refreshControl={
            <RefreshControl
              refreshing={scrollRefreshing}
              onRefresh={handleRefresh}
            />
          }
          ListHeaderComponent={
            <Box key="header">
              <HStack alignItems="center">
                <Heading size="md" numberOfLines={2} flex={1}>
                  {commitment.title}
                </Heading>
                {commitmentIsDeleted(commitment) ? (
                  <Text
                    p={2}
                    borderRadius={6}
                    borderWidth="1px"
                    borderColor="red.500"
                    color="red.500"
                  >
                    {"Deleted "}
                  </Text>
                ) : (
                  <IconButton
                    icon={<Feather name="edit" size={20} />}
                    onPress={() =>
                      navigation.navigate("CommitmentUpdateScreen", {
                        id: route.params.id,
                      })
                    }
                  />
                )}
              </HStack>
              <Box mt={2} borderBottomWidth="1px" borderColor="gray.300" />
              <Heading mt={2} size="sm">
                Required evidence
              </Heading>
              <Text>{commitment.evidence_description}</Text>
              <Heading mt={2} size="sm">
                Repeats
              </Heading>
              <Text>
                {getHumanizedRepeatDescription(
                  commitment.repeat_value,
                  commitment.repeat_unit,
                  commitment.repeat_days,
                  commitment.initial_deadline,
                  commitment.initial_deadline,
                  commitment.deadline_time,
                  commitment.timezone
                )}
              </Text>
              <Heading mt={2} size="sm">
                Punishment
              </Heading>
              <Text>
                {getHumanizedPunishmentDescription(
                  commitment.punishment_on_value,
                  commitment.punishment_amount_value,
                  commitment.punishment_amount_unit
                )}
              </Text>
              <Heading mt={2} size="sm">
                Reviewer
              </Heading>
              <Text>{commitment.reviewer_email}</Text>
              {commitmentIsUpdated(commitment) &&
              commitment.transition_at !== null ? (
                <>
                  <Heading mt={2} size="sm" color="orange.500">
                    Updates on
                  </Heading>
                  <Text>
                    {new Date(commitment.transition_at).toDateString()}
                  </Text>
                </>
              ) : (
                <></>
              )}
              <Heading mt={2} size="sm">
                Skips remaining
              </Heading>
              <Text>
                {calculateRemainingSkipAllowance(
                  commitment,
                  nowOrInitialDeadline(commitment)
                )}
              </Text>
              <Heading mt={2} size="sm">
                Deadlines
              </Heading>
            </Box>
          }
          data={instances ? instances : []}
          keyExtractor={(item) => item.id}
          renderItem={({ item }) => {
            const [summary, color] = getHumanizedStatusSummary(item);
            return (
              <TouchableOpacity
                onPress={() => {
                  analytics.trackEvent("CLICKED_INSTANCE_DETAILS");
                  navigation.navigate("InstanceScreen", { id: item.id });
                }}
              >
                <Box
                  borderWidth={1}
                  borderRadius="6px"
                  padding={2}
                  marginTop={2}
                  borderColor={"gray.300"}
                >
                  <HStack alignItems="center">
                    <VStack flex={1}>
                      <Heading size="sm">
                        {`${new Date(item.due_at).toDateString()} at ${new Date(
                          item.due_at
                        ).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}`}
                      </Heading>
                      <Text color={color} mr={2}>
                        {summary}
                      </Text>
                    </VStack>
                    {[null, PunishmentStatus.NOT_REQUIRED].includes(
                      item.punishment_status
                    ) ? (
                      <></>
                    ) : (
                      <Ionicons name="cash-outline" size={25} color="red" />
                    )}
                  </HStack>
                </Box>
              </TouchableOpacity>
            );
          }}
        />
      </UserBody>
    );
  } else {
    return <></>;
  }
}
