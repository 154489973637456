import React from "react";
import { extendTheme, NativeBaseProvider } from "native-base";
import StripeProvider from "./components/payments/StripeProvider";
import Navigation from "./navigation/Navigator";
import UserProvider from "./components/UserProvider";
import AppLoading from "expo-app-loading";
import { theme, useFonts, sizes } from "./theme";
import AnalyticsProvider from "./components/AnalyticsProvider";

let publishableKey: string;
if (__DEV__) {
  publishableKey =
    "pk_test_51JIIREBvFNolSYjBqc0NV85yTGXraCxst2LI0quA6uxHn4hsMLmsRwJdCO8acZPA9sDhpR4dxhquqSDMzZhjzFB6003ZMTGtDz";
} else {
  publishableKey =
    "pk_live_51JIIREBvFNolSYjBdQekzLsrs9QPcwhyNMDX7b1DixXI4GVbSJWA4VR0GjPVtOzefix1Ck5zVpCffmkeeq3S8yEe00T0BWehqQ";
}

export default function App() {
  let [fontsLoaded] = useFonts(sizes);

  if (!fontsLoaded) {
    return <AppLoading />;
  } else {
    return (
      <NativeBaseProvider theme={theme}>
        <StripeProvider publishableKey={publishableKey}>
          <AnalyticsProvider>
            <UserProvider>
              <Navigation />
            </UserProvider>
          </AnalyticsProvider>
        </StripeProvider>
      </NativeBaseProvider>
    );
  }
}
