import { Input } from "native-base";
import React, { useRef, useState } from "react";
import { Platform, TextInput } from "react-native";
import WebDateTimePicker from "./WebDateTimePicker";
import MobileDateTimePicker from "./MobileDateTimePicker";

interface DateTimePickerProps {
  type: "date" | "time";
  value: Date;
  onChange: (value: any) => any;
}

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  type,
  value,
  onChange,
}) => {
  if (Platform.OS === "web") {
    return <WebDateTimePicker type={type} value={value} onChange={onChange} />;
  } else {
    return <MobileDateTimePicker type={type} value={value} onChange={onChange} />;
  }
};

export default DateTimePicker;
