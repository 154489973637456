import { Ionicons } from "@expo/vector-icons";
import { ResizeMode, Video } from "expo-av";
import {
  FlatList,
  Heading,
  View,
  Image,
  Box,
  AspectRatio,
  ScrollView,
  Modal,
  Button,
  Center,
  HStack,
  VStack,
  IconButton,
  Spacer,
} from "native-base";
import { ItemClick } from "native-base/lib/typescript/components/composites/Typeahead/useTypeahead/types";
import React, { useState } from "react";
import { Touchable, TouchableOpacity, useWindowDimensions } from "react-native";
import FullWidthImage from "react-native-fullwidth-image";
import { FlatGrid } from "react-native-super-grid";

import { EvidenceFile } from "../lib/types";
import ResponsiveImageBackground from "./ResponsiveImageBackground";
import ResponsiveVideoBackground from "./ResponsiveVideoBackground";

interface MediaGalleryProps {
  mediaFiles: EvidenceFile[];
  itemDimension: number;
}

const MediaGallery: React.FC<MediaGalleryProps> = ({ mediaFiles, itemDimension }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [fileIndex, setFileIndex] = useState(0);

  const { height, width } = useWindowDimensions();

  const handlePress = (index: number) => {
    setFileIndex(index);
    setModalOpen(true);
  };

  return (
    <Box>
      <FlatGrid
        fixed={true}
        itemDimension={itemDimension}
        data={mediaFiles}
        keyExtractor={(item) => item.url}
        renderItem={({ item, index }) => {
          if (item.mime_type.startsWith("image")) {
            return (
              <TouchableOpacity onPress={() => handlePress(index)}>
                <AspectRatio ratio={1} w={itemDimension}>
                  <Image
                    flex={1}
                    source={{ uri: item.url }}
                    alt=" "
                    resizeMode="cover"
                  />
                </AspectRatio>
              </TouchableOpacity>
            );
          } else {
            return (
              <TouchableOpacity onPress={() => handlePress(index)}>
                <AspectRatio ratio={1} w={itemDimension}>
                  <Video
                    source={{ uri: item.url }}
                    resizeMode="cover"
                  />
                </AspectRatio>
              </TouchableOpacity>
            );
          }
        }}
      />
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <Modal.Content h="100%">
          {mediaFiles[fileIndex].mime_type.startsWith("image") ? (
            <ResponsiveImageBackground
              source={{ uri: mediaFiles[fileIndex].url }}
              resizeMode="contain"
              fileIndex={fileIndex}
              numFiles={mediaFiles.length}
              onClose={() => setModalOpen(false)}
              onForwards={() => setFileIndex((i) => i + 1)}
              onBackwards={() => setFileIndex((i) => i - 1)}
            />
          ) : (
            <ResponsiveVideoBackground
              source={{ uri: mediaFiles[fileIndex].url }}
              resizeMode={ResizeMode.CONTAIN}
              fileIndex={fileIndex}
              numFiles={mediaFiles.length}
              onClose={() => setModalOpen(false)}
              onForwards={() => setFileIndex((i) => i + 1)}
              onBackwards={() => setFileIndex((i) => i - 1)}
            />
          )}
        </Modal.Content>
      </Modal>
    </Box>
  );
};

export default MediaGallery;
