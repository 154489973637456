import { useNavigation } from "@react-navigation/core";
import React, { SetStateAction, useCallback, useEffect, useState } from "react";
import { Alert, Text } from "react-native";

export type RecordKey = string | number;

export interface ElementProps<StateType> {
  stage: RecordKey;
  setStage: React.Dispatch<React.SetStateAction<RecordKey>>;
  state: StateType;
  setState: React.Dispatch<React.SetStateAction<StateType>>;
  onCompletion: React.Dispatch<void>;
  informParentOfChild: React.Dispatch<React.SetStateAction<boolean>>;
  parentGoBack: (e: any) => void;
}

export interface StageChangeEvent<StateType> {
  prevStage: RecordKey;
  newStage: RecordKey;
  state: StateType;
}

export interface StateChangeEvent<StateType> {
  prevState: StateType;
  newState: StateType;
  stage: RecordKey;
}

export interface CompletionEvent<StateType> {
  stage: RecordKey;
  state: StateType;
}

export interface GushProps<StateType> {
  config:
    | Record<
        RecordKey,
        | ((props: ElementProps<StateType>) => JSX.Element)
        | React.FC<ElementProps<StateType>>
      >
    | Partial<
        Record<
          RecordKey,
          | ((props: ElementProps<StateType>) => JSX.Element)
          | React.FC<ElementProps<StateType>>
        >
      >;
  initialStage: RecordKey;
  initialState: StateType;
  onStageChange?: (event: StageChangeEvent<StateType>) => void;
  onStateChange?: (event: StateChangeEvent<StateType>) => void;
  onCompletion?: (event: CompletionEvent<StateType>) => void;
  informParentOfChild?: React.Dispatch<SetStateAction<boolean>>;
  parentGoBack?: (e: any) => void;
}

export default function Gush<StateType>(props: GushProps<StateType>) {
  const {
    config,
    initialStage,
    initialState,
    onStageChange,
    onStateChange,
    onCompletion,
    informParentOfChild,
    parentGoBack,
  } = props;
  const navigation = useNavigation();
  const [history, setHistory] = useState([initialStage]);
  const [stage, setStage] = useState(initialStage);
  const [state, setState] = useState(initialState);
  const [hasChild, setHasChild] = useState(false);

  useEffect(() => {
    if (informParentOfChild !== undefined) {
      informParentOfChild(true);
      return () => {
        informParentOfChild(false);
      };
    }
  }, [informParentOfChild]);

  const goBack = useCallback(
    (e) => {
      if (e.data.action.type === "POP") {
        if (history.length > 1) {
          const newStageHistory = history.slice(0, -1);
          setHistory(newStageHistory);
          setStage(newStageHistory[newStageHistory.length - 1]);
        } else if (parentGoBack) {
          parentGoBack(e);
        } else {
          navigation.dispatch(e.data.action);
        }
      } else {
        navigation.dispatch(e.data.action);
      }
    },
    [history, setStage, hasChild]
  );

  useEffect(() => {
    const unsubscribe = navigation.addListener("beforeRemove", (e) => {
      e.preventDefault();
      if (!hasChild) {
        goBack(e);
      }
    });
    return unsubscribe;
  }, [navigation, goBack]);

  const handleStageChange = (value: React.SetStateAction<RecordKey>) => {
    const newValue = (prevStage: RecordKey) => {
      let newStage: RecordKey;
      if (value instanceof Function) {
        newStage = value(prevStage);
      } else {
        newStage = value;
      }
      setHistory((prevHistory) => [...prevHistory, newStage]);
      if (onStageChange) {
        onStageChange({ prevStage, newStage, state });
      }
      return newStage;
    };
    setStage(newValue);
  };

  const handleStateChange = (value: React.SetStateAction<StateType>) => {
    const newValue = (prevState: StateType) => {
      let newState: StateType;
      if (value instanceof Function) {
        newState = value(prevState);
      } else {
        newState = value;
      }
      if (onStateChange) {
        onStateChange({ prevState, newState, stage });
      }
      return newState;
    };
    setState(newValue);
  };

  const handleCompletion = () => {
    if (onCompletion) {
      onCompletion({ stage, state });
    }
  };

  const element = config[stage];
  if (element) {
    return React.createElement(element, {
      stage,
      setStage: handleStageChange,
      state,
      setState: handleStateChange,
      onCompletion: handleCompletion,
      informParentOfChild: setHasChild,
      parentGoBack: goBack,
    });
  } else {
    return <Text>Oops, something went wrong!</Text>;
  }
}
