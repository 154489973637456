import { Box, Button, FormControl, TextArea } from "native-base";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ElementProps } from "../../components/gush";
import { EvidenceAddFormState } from "../../lib/types";

const EvidenceAddText: React.FC<ElementProps<EvidenceAddFormState>> = ({
  state,
  setState,
  onCompletion,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (state.evidenceText !== null) {
      setSubmitting(false);
      onCompletion();
    }
  }, [setState, state]);

  const onSubmit = (data: { text: string }) => {
    setSubmitting(true);
    setState({ evidenceText: data.text, evidenceFileInfo: null });
  };

  return (
    <Box>
      <FormControl mt={2} isRequired isInvalid={"text" in errors}>
        <FormControl.Label>{"Evidence Text "}</FormControl.Label>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextArea
              type="text"
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
            />
          )}
          name="text"
          rules={{
            required: "Field is required",
          }}
          defaultValue=""
        />
        <FormControl.ErrorMessage>
          {errors.text?.message}
        </FormControl.ErrorMessage>
      </FormControl>
      <Button
        mt={2}
        onPress={handleSubmit(onSubmit)}
        isDisabled={submitting}
        isLoading={submitting}
      >
        Submit
      </Button>
    </Box>
  );
};

export default EvidenceAddText;
