import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItemList,
  DrawerContentComponentProps,
  DrawerItem,
} from "@react-navigation/drawer";
import * as React from "react";
import { Box, Heading } from "native-base";

import TabIcon from "../TabIcon";
import tabs from "../tabs/tabs";
import { fontFamily } from "../../theme";

const DrawerContent: React.FC<DrawerContentComponentProps> = (props) => (
  <Box>
    <Box h="64px" justifyContent="center">
      <Heading size="lg" pl="16px">
        Kommit
      </Heading>
    </Box>
    <DrawerContentScrollView {...props}>
      <DrawerItemList {...props} />
    </DrawerContentScrollView>
  </Box>
);

const Drawer = createDrawerNavigator();

const WebNavigator: React.FC = () => {
  return (
    <Box
      h="100%"
      w={["100%", "100%", 768]}
      ml="auto"
      mr="auto"
      borderRightColor="gray.300"
      borderRightWidth="1px"
    >
      <Drawer.Navigator
        initialRouteName="Commitments"
        screenOptions={{
          header: () => null,
          drawerType: "permanent",
          drawerStyle: {
            width: 200,
          },
          drawerLabelStyle: { fontFamily },
        }}
        drawerContent={DrawerContent}
      >
        {tabs.map((tab, i) => {
          return (
            <Drawer.Screen
              name={tab.name}
              component={tab.component}
              options={{
                drawerIcon: () => (
                  <TabIcon
                    name={tab.name}
                    size={20}
                    style={{ marginRight: -20 }}
                  />
                ),
              }}
              key={tab.name}
            />
          );
        })}
      </Drawer.Navigator>
    </Box>
  );
};

export default WebNavigator;
