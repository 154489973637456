import Constants from "expo-constants";
import { Modal } from "native-base";
import React, { useEffect, useState } from "react";
import { Platform } from "react-native";
import { useAnnouncement } from "../lib/hooks";

const AnnouncementModal: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const appVersion = Constants.manifest?.version;
  const { announcement } = useAnnouncement(
    Platform.OS,
    appVersion ? appVersion : "none"
  );

  useEffect(() => {
    if (announcement) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  }, [announcement]);

  if (announcement) {
    return (
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <Modal.Content maxW={400}>
          <Modal.CloseButton />
          <Modal.Header>{announcement.header}</Modal.Header>
          <Modal.Body>{announcement.body}</Modal.Body>
        </Modal.Content>
      </Modal>
    );
  } else {
    return <></>;
  }
};

export default AnnouncementModal;
