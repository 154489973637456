import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

let publishableKey: string;
if (__DEV__) {
  publishableKey =
    "pk_test_51JIIREBvFNolSYjBqc0NV85yTGXraCxst2LI0quA6uxHn4hsMLmsRwJdCO8acZPA9sDhpR4dxhquqSDMzZhjzFB6003ZMTGtDz";
} else {
  publishableKey =
    "pk_live_51JIIREBvFNolSYjBdQekzLsrs9QPcwhyNMDX7b1DixXI4GVbSJWA4VR0GjPVtOzefix1Ck5zVpCffmkeeq3S8yEe00T0BWehqQ";
}

const stripePromise = loadStripe(publishableKey);

const StripeProvider: React.FC<{ publishableKey: any }> = ({ children }) => (
  <Elements stripe={stripePromise}>{children}</Elements>
);

export default StripeProvider;
