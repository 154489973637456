import AccountNavigator from "../tabs/AccountNavigator";
import CommitmentsNavigator from "../tabs/CommitmentsNavigator";
import ReviewsNavigator from "../tabs/ReviewsNavigator";
import SupportNavigator from "../tabs/SupportNavigator";
import { UserTabParamList } from "../types";

interface Tab {
  name: keyof UserTabParamList;
  component: React.FC;
}

const tabs: Tab[] = [
  { name: "Commitments", component: CommitmentsNavigator },
  { name: "Reviews", component: ReviewsNavigator },
  { name: "Account", component: AccountNavigator },
  { name: "Support", component: SupportNavigator },
];

export default tabs;
