import React, { useState } from "react";
import { Button, Text, Heading, Input, ScrollView } from "native-base";
import { useForm, Controller } from "react-hook-form";
import { CommitmentAddFormState, Currency } from "../../lib/types";
import { ElementProps } from "../../components/gush";
import {
  getHumanizedPunishmentDescription,
  getHumanizedRepeatDescription,
  getRepeatDaysArray,
} from "../../lib/utils";
import { useAnalytics } from "../../components/AnalyticsProvider";

const CommitmentDescription: React.FC<{ state: CommitmentAddFormState }> = ({
  state,
}) => {
  const {
    repeatValue,
    repeatUnit,
    repeatDays,
    initialDeadlineDate,
    initialDeadlineTime,
    timezone,
  } = state.CommitmentConfigure;

  const repeatDescription = getHumanizedRepeatDescription(
    repeatValue,
    repeatUnit,
    getRepeatDaysArray(repeatUnit, repeatDays),
    initialDeadlineDate,
    initialDeadlineTime,
    undefined,
    timezone,
  );

  const { punishmentOnValue, punishmentAmountValue, punishmentAmountUnit } =
    state.PunishmentConfigure;
  const punishmentDescription = getHumanizedPunishmentDescription(
    punishmentOnValue,
    punishmentAmountValue,
    punishmentAmountUnit
  );

  return (
    <>
      <Heading mt={2} size="sm">
        Title
      </Heading>
      <Text>{state.CommitmentConfigure.title}</Text>
      <Heading mt={2} size="sm">
        Required Evidence
      </Heading>
      <Text>{state.CommitmentConfigure.evidenceDescription}</Text>
      <Heading mt={2} size="sm">
        Repeats
      </Heading>
      <Text>{repeatDescription}</Text>
      <Heading mt={2} size="sm">
        Punishment
      </Heading>
      <Text>{punishmentDescription}</Text>
      <Heading mt={2} size="sm">
        Reviewer
      </Heading>
      <Text>{state.ReviewerConfigure.reviewerEmail}</Text>
      <Heading mt={2} size="sm">
        Important
      </Heading>
      <Text
        mt={2}
        p={2}
        borderColor="gray.300"
        borderWidth="1px"
        borderRadius="6px"
      >{`If you later decide that you want to change these details or cancel your commitment, you will have to complete a further ${punishmentOnValue} occurrences before the changes take effect. This keeps you accountable as there is no way to dodge punishments. Please make sure you are happy to stick with these setting for some time!`}</Text>
    </>
  );
};

const CommitmentConfirm: React.FC<ElementProps<CommitmentAddFormState>> = ({
  stage,
  state,
  onCompletion,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const analytics = useAnalytics();

  const handleCompletion = async () => {
    setSubmitting(true);
    await onCompletion();
    setSubmitting(false);
  };

  return (
    <ScrollView key={stage} flex={1}>
      <Heading size="md">{"Confirm Commitment "}</Heading>
      <CommitmentDescription state={state} />
      <Button
        mt={2}
        onPress={() => {
          analytics.trackEvent("CONFIRMED_COMMITMENT_CREATION");
          handleCompletion();
        }}
        isDisabled={submitting}
        isLoading={submitting}
      >
        {"Confirm "}
      </Button>
    </ScrollView>
  );
};

export default CommitmentConfirm;
