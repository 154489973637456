import React from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  Flex,
  Link,
  Center,
  VStack,
  HStack,
  Spacer,
  Stack,
  Image,
} from "native-base";
import { useNavigation } from "@react-navigation/native";
import * as Localization from "expo-localization";
import { ScrollView, useWindowDimensions } from "react-native";
import { Video } from "expo-av";
import directHit from "../assets/images/direct-hit_1f3af.png";
import moneyBag from "../assets/images/money-bag_1f4b0.png";
import manDetective from "../assets/images/man-detective_1f575-fe0f-200d-2642-fe0f.png";
import receipt from "../assets/images/receipt_1f9fe.png";

const getCurrencySymbol = () => {
  const timeZone = Localization.timezone;
  if (timeZone.startsWith("Europe/London")) {
    return "£";
  } else if (timeZone.startsWith("Europe")) {
    return "€";
  } else {
    return "$";
  }
};

const LandingScreen = () => {
  const navigation = useNavigation();
  const { width } = useWindowDimensions();
  const direction = width < 700 ? "column" : "row";
  const w = width < 700 ? "100%" : "20%";
  return (
    <>
      <Flex
        marginLeft="auto"
        marginRight="auto"
        width={["100%", "%100", 768, 992]}
        height="100%"
      >
        <ScrollView>
          <VStack space={8} mt={8} mb={8}>
            <Box p={4}>
              <Heading size="2xl" textAlign="left">
                {`Make your habits stick.`}
              </Heading>
              <Text fontSize="xl" textAlign="left">
                Building new habits is hard. 50% of people fail to keep their
                New Year's Resolutions. Kommit will help you stick with your
                commitments by requiring that you show evidence to avoid
                punishment.
              </Text>
              <Button
                w={32}
                onPress={() => navigation.navigate("SignUpScreen")}
                mt={2}
              >
                {"Get Started "}
              </Button>
            </Box>
            <Box
              p={4}
              bgColor="gray.200"
              borderColor="gray.200"
              borderWidth="1px"
              borderRadius={4}
            >
              <Heading size="2xl" textAlign="left">
                {`How it works.`}
              </Heading>
              <Text fontSize="xl" textAlign="left">
                Kommit is like a contract for your habits. You define your
                commitment and Kommit will charge you if you fail to keep to it.
              </Text>
              <Stack w="100%" mt={4} mb={4} direction={direction} space={2}>
                <Spacer />
                <Center
                  borderWidth={0}
                  borderColor="rgb(64, 64, 64)"
                  minW={150}
                  h={150}
                  w={w}
                  bgColor="white"
                  borderRadius={1}
                >
                  <Image
                    source={directHit}
                    alt=""
                    style={{ height: 60, width: 60 }}
                  ></Image>
                  <Heading size="md" textAlign="center">
                    {`Choose\nCommitment`}
                  </Heading>
                </Center>
                <Spacer />
                <Center
                  borderWidth={0}
                  borderColor="rgb(64, 64, 64)"
                  minW={150}
                  w={w}
                  h={150}
                  bgColor="white"
                  borderRadius={4}
                >
                  <Image
                    source={receipt}
                    alt=""
                    style={{ height: 60, width: 60 }}
                  ></Image>
                  <Heading size="md" textAlign="center">
                    {`Define\nEvidence`}
                  </Heading>
                </Center>
                <Spacer />
                <Center
                  borderWidth={0}
                  borderColor="rgb(64, 64, 64)"
                  minW={150}
                  w={w}
                  h={150}
                  bgColor="white"
                  borderRadius={4}
                >
                  <Image
                    source={moneyBag}
                    alt=""
                    style={{ height: 60, width: 60 }}
                  ></Image>
                  <Heading size="md" textAlign="center">
                    {`Set\nPunishment`}
                  </Heading>
                </Center>
                <Spacer />
                <Center
                  borderWidth={0}
                  borderColor="rgb(64, 64, 64)"
                  minW={150}
                  w={w}
                  h={150}
                  bgColor="white"
                  borderRadius={4}
                >
                  <Image
                    source={manDetective}
                    alt=""
                    style={{ height: 60, width: 60 }}
                  ></Image>
                  <Heading size="md" textAlign="center">
                    {`Select\nReviewer`}
                  </Heading>
                </Center>
                <Spacer />
              </Stack>
              <Text fontSize="xl" textAlign="left" mb={2}>
                You will need to regularly upload evidence of your commitments
                for your reviewer to check.
              </Text>
            </Box>
            <Box p={4}>
              <Heading size="2xl" textAlign="left">
                {`It's free.`}
              </Heading>
              <Text fontSize="xl" textAlign="left" textDecorationColor="blue">
                Kommit is free to use. The money charged when you fail to keep a
                commitment is donated to{" "}
                <Link
                  href="https://funds.effectivealtruism.org/funds/global-development"
                  isExternal
                >
                  <Text fontSize="xl" textAlign="left" color="cyan.400">
                    charity
                  </Text>
                </Link>{" "}
                💸
              </Text>
            </Box>
            <Box
              p={4}
              bgColor="gray.200"
              borderColor="gray.200"
              borderWidth="1px"
              borderRadius={4}
            >
              <Heading size="2xl" textAlign="left">
                {`Demonstration.`}
              </Heading>
              <Text fontSize="xl" textAlign="left" mb={4}>
                If you're still not sure, take a look at this demonstration.
              </Text>
              <Center>
                <Box w={[200, 400, 700, 900]} mb={4}>
                  <Video
                    style={{ flex: 1 }}
                    source={{
                      uri: "https://storage.googleapis.com/promo-videos/Kommit%20Demonstration%20-%20HD%201080p.mov",
                    }}
                    resizeMode="contain"
                    isLooping={false}
                    isMuted={false}
                    useNativeControls
                  />
                </Box>
              </Center>
            </Box>
          </VStack>
        </ScrollView>
        <Center h="60px" borderTopColor="gray.300" borderTopWidth={1}>
          <Text>
            <Link
              href="https://www.iubenda.com/privacy-policy/49876814"
              isExternal
            >
              Privacy Policy
            </Link>{" "}
            and{" "}
            <Link
              href="https://www.iubenda.com/privacy-policy/49876814/cookie-policy"
              isExternal
            >
              Cookie Policy
            </Link>{" "}
          </Text>
        </Center>
      </Flex>
    </>
  );
};

export default LandingScreen;
