import * as React from "react";
import {
  Box,
  Button,
  FormControl,
  Heading,
  Input,
  ScrollView,
  Text,
  TextArea,
  Modal,
} from "native-base";
import UserBody from "../components/UserBody";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { useUser } from "../lib/hooks";
import { sendUserSupportMessage } from "../lib/api";
import { useAnalytics } from "../components/AnalyticsProvider";

interface UserSupportFormState {
  subject: string;
  message: string;
}

export default function SupportScreen() {
  const user = useUser();
  const analytics = useAnalytics();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: { subject: "", message: "" } });

  const [modalOpen, setModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async (data: UserSupportFormState) => {
    if (user) {
      setSubmitting(true);
      await sendUserSupportMessage(user, data.subject, data.message);
      setSubmitting(false);
      setModalOpen(true);
      reset();
    }
  };

  return (
    <UserBody h="100%">
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <Modal.Content maxW={400}>
          <Modal.CloseButton />
          <Modal.Header>Message Sent</Modal.Header>
          <Modal.Body>
            Thanks for your message, we'll be in touch shortly!
          </Modal.Body>
        </Modal.Content>
      </Modal>
      <Text>
        Having to trouble? Send us a message and we'll get in touch (via email)
        as soon as we can!
      </Text>
      <Box mt={2} borderBottomWidth="1px" borderColor="gray.300" />
      {/* Subject */}
      <FormControl mt={2} isRequired isInvalid={"subject" in errors}>
        <FormControl.Label>{"Subject "}</FormControl.Label>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              type="text"
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
            />
          )}
          name="subject"
          rules={{
            required: "Field is required",
          }}
        />
        <FormControl.ErrorMessage>
          {errors.subject?.message}
        </FormControl.ErrorMessage>
      </FormControl>

      {/* Message */}
      <FormControl mt={2} isRequired isInvalid={"message" in errors}>
        <FormControl.Label>{"Message "}</FormControl.Label>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextArea onBlur={onBlur} onChangeText={onChange} value={value} />
          )}
          name="message"
          rules={{ required: "Field is required" }}
        />
        <FormControl.ErrorMessage>
          {errors.message?.message}
        </FormControl.ErrorMessage>
      </FormControl>

      <Button
        mt={2}
        onPress={(data) => {
          analytics.trackEvent("SUBMITTED_SUPPORT_FORM");
          handleSubmit(onSubmit)(data);
        }}
        isDisabled={submitting}
        isLoading={submitting}
      >
        {"Submit "}
      </Button>
    </UserBody>
  );
}
