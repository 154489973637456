export interface User {
  telegram_id: string | null;
  telegram_key: string | null;
}

export interface RepeatDays {
  MONDAY: boolean;
  TUESDAY: boolean;
  WEDNESDAY: boolean;
  THURSDAY: boolean;
  FRIDAY: boolean;
  SATURDAY: boolean;
  SUNDAY: boolean;
}

export enum DayOfWeek {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
}

export enum TimeUnit {
  MINUTES = "MINUTES",
  HOURS = "HOURS",
  DAYS = "DAYS",
  WEEKS = "WEEKS",
  MONTHS = "MONTHS",
  YEARS = "YEARS",
}

export enum EvidenceSubmissionWindowOption {
  AFTER_PREVIOUS_DEADLINE = "AFTER_PREVIOUS_DEADLINE",
  DAY_OF_DEADLINE = "DAY_OF_DEADLINE",
  WEEK_OF_DEADLINE = "WEEK_OF_DEADLINE",
  MONTH_OF_DEADLINE = "MONTH_OF_DEADLINE",
  TIME_DELTA = "TIME_DELTA",
}

export enum Currency {
  GBP = "GBP",
  EUR = "EUR",
  USD = "EUR",
}

export interface CommitmentConfigureFormState {
  title: string;
  initialDeadlineDate: Date;
  initialDeadlineTime: Date;
  timezone: string;
  repeatValue: number;
  repeatUnit: TimeUnit;
  repeatDays?: RepeatDays;
  evidenceDescription: string;
  evidenceSubmissionWindowOption: EvidenceSubmissionWindowOption;
  evidenceSubmissionWindowValue?: number;
  evidenceSubmissionWindowUnit?: TimeUnit;
}

export interface PunishmentConfigureFormState {
  punishmentOnValue: number;
  punishmentRecipient: string;
  punishmentAmountValue: number;
  punishmentAmountUnit: Currency;
  skipAllowance: number;
}

export interface ReviewerConfigureFormState {
  reviewerEmail: string;
}

export interface PaymentMethodConfigureFormState {
  paymentMethod: string;
}

export interface CommitmentAddFormState {
  CommitmentConfigure: CommitmentConfigureFormState;
  PunishmentConfigure: PunishmentConfigureFormState;
  ReviewerConfigure: ReviewerConfigureFormState;
  PaymentMethodConfigure: PaymentMethodConfigureFormState;
}

export interface CommitmentCreate {
  title: string;
  initial_deadline: Date;
  timezone: string;
  repeat_value: number;
  repeat_unit: TimeUnit;
  repeat_days: DayOfWeek[] | null;
  evidence_description: string;
  evidence_submission_window_option: EvidenceSubmissionWindowOption;
  evidence_submission_window_value: number | null;
  evidence_submission_window_unit: TimeUnit | null;
  punishment_on_value: number;
  punishment_recipient: string;
  punishment_amount_value: number;
  punishment_amount_unit: Currency;
  skip_allowance: number;
  reviewer_email: string;
  payment_method: string;
}

export interface CommitmentUpdate {
  title: string;
  deadline_time: string;
  timezone: string;
  repeat_value: number;
  repeat_unit: TimeUnit;
  repeat_days: DayOfWeek[] | null;
  evidence_description: string;
  evidence_submission_window_option: EvidenceSubmissionWindowOption;
  evidence_submission_window_value: number | null;
  evidence_submission_window_unit: TimeUnit | null;
  punishment_on_value: number;
  punishment_recipient: string;
  punishment_amount_value: number;
  punishment_amount_unit: Currency;
  skip_allowance: number;
  reviewer_email: string;
  payment_method: string;
}

export enum CommitmentStatus {
  PREACTIVE = "PREACTIVE",
  ACTIVE = "ACTIVE",
  POSTACTIVE = "POSTACTIVE",
}

export interface CommitmentRead extends CommitmentCreate {
  id: string;
  entity_id: string;
  created: Date;
  updated: Date;
  deadline_time: string;
  skip_count_by_year: Record<string, number>
  latest_instance: string | null;
  create_instances_at: Date | null;
  status: CommitmentStatus;
  transition_at: Date | null;
  transition_to: string | null;
}

export enum InstanceStatus {
  INVISIBLE = "INVISIBLE",
  VISIBLE = "VISIBLE",
  EXPIRED = "EXPIRED",
}

export enum EvidenceSubmissionStatus {
  NOT_SUBMITTED = "NOT_SUBMITTED",
  SUBMITTED = "SUBMITTED",
  SKIPPED = "SKIPPED"
}

export enum EvidenceReviewStatus {
  NOT_SUBMITTED = "NOT_SUBMITTED",
  APPROVED = "APPROVED",
  DENIED = "DENIED",
}

export enum PunishmentStatus {
  REQUIRED = "REQUIRED",
  NOT_REQUIRED = "NOT_REQUIRED",
  IN_PROGRESS = "IN_PROGRESS",
  PROBLEM = "PROBLEM",
  COMPLETE = "COMPLETE",
}

export interface EvidenceFile {
  filename: string;
  mime_type: string;
  caption: string | null;
  url: string;
}

export enum NotificationStatus {
  NOT_REQUIRED = "NOT_REQUIRED",
  NOT_SENT = "NOT_SUBMITTED",
  SENT = "SENT",
}

export interface Notifications {
  minus_60_mins: NotificationStatus;
  minus_30_mins: NotificationStatus;
  minus_15_mins: NotificationStatus;
}

export interface InstanceRead {
  id: string;
  created: Date;
  updated: Date;
  commitment: string;

  due_at: Date;
  visible_at: Date;
  expires_at: Date;

  title: string;
  evidence_description: string;
  reviewer_email: string;

  evidence_text: string;
  evidence_photos: EvidenceFile[];
  evidence_videos: EvidenceFile[];

  status: InstanceStatus;
  evidence_submission_status: EvidenceSubmissionStatus;
  evidence_review_status: EvidenceReviewStatus;
  punishment_status: PunishmentStatus | null;

  notifications: Notifications;
}

export interface UserRead {
  email: string;
  timezone: string;
  telegram_key: string;
  telegram_id: string | null;
}

export interface CardRead {
  brand: string;
  last4: string;
  exp_month: number;
  exp_year: number;
  country: string;
}

export interface PaymentMethodRead {
  id: string;
  card: CardRead;
}

export interface AnnouncementRead {
  os: string;
  app_version: string;
  header: string;
  body: string;
}

// Evidence

export enum URIType {
  path = "path",
  uri = "uri",
}

export enum MediaType {
  image = "image",
  video = "video",
}

export interface FileInfo {
  uri: string;
  uriType: URIType;
  type: "image" | "video";
}

export interface EvidenceAddFormState {
  evidenceText: string | null;
  evidenceFileInfo: FileInfo | null;
}
