import { useCallback, useContext, useEffect, useState } from "react";
import firebase from "firebase";

import { UserContext } from "../components/UserProvider";
import {
  listCommitmentEntityInstances,
  listCommitments,
  listInstanceReviews,
  listPaymentMethods,
  readAnnouncement,
  readCommitment,
  readInstance,
  readInstanceReview,
  readPaymentMethod,
  readUser,
} from "./api";
import * as T from "./types";

export const useUser = () => {
  const { user } = useContext(UserContext);
  return user;
};

export function useApi<T>(
  apiCall: (user: firebase.User) => Promise<T>,
  isFocused?: boolean
) {
  const user = useUser();
  const [data, setData] = useState<T | null>(null);
  const [refreshing, setRefreshing] = useState(false);

  // Get data from api call
  const getData = async () => {
    if (user && !refreshing) {
      setRefreshing(true);
      const result = await apiCall(user);
      setData(result);
      setTimeout(() => setRefreshing(false), 100);
    }
  };

  // Get data on mount
  useEffect(() => {
    if (isFocused === undefined || isFocused) {
      getData();
    }
  }, [user, isFocused]);

  const refresh = useCallback(getData, [apiCall]);

  return { data, refresh, refreshing };
}

export const useUserInfo = (isFocused?: boolean) => {
  const { data, refresh, refreshing } = useApi<T.UserRead | null>(
    readUser,
    isFocused
  );
  return { userInfo: data, refresh, refreshing };
};

export const useCommitments = (isFocused?: boolean) => {
  const { data, refresh, refreshing } = useApi<T.CommitmentRead[] | null>(
    listCommitments,
    isFocused
  );
  return { commitments: data, refresh, refreshing };
};

export const useCommitment = (commitmentId: string, isFocused?: boolean) => {
  const { data, refresh, refreshing } = useApi<T.CommitmentRead | null>(
    (user) => readCommitment(user, commitmentId),
    isFocused
  );
  return { commitment: data, refresh, refreshing };
};

export const useCommitmentInstances = (
  commitmentEntityId: string,
  isFocused?: boolean
) => {
  const { data, refresh, refreshing } = useApi<T.InstanceRead[] | null>(
    (user) => listCommitmentEntityInstances(user, commitmentEntityId),
    isFocused
  );
  return { instances: data, refresh, refreshing };
};

export const useInstance = (instanceId: string, isFocused?: boolean) => {
  const { data, refresh, refreshing } = useApi<T.InstanceRead | null>(
    (user) => readInstance(user, instanceId),
    isFocused
  );
  return { instance: data, refresh, refreshing };
};

export const useInstanceReviews = (isFocused?: boolean) => {
  const { data, refresh, refreshing } = useApi<T.InstanceRead[] | null>(
    (user) => listInstanceReviews(user),
    isFocused
  );
  return { instances: data, refresh, refreshing };
};

export const useInstanceReview = (instanceId: string, isFocused?: boolean) => {
  const { data, refresh, refreshing } = useApi<T.InstanceRead | null>(
    (user) => readInstanceReview(user, instanceId),
    isFocused
  );
  return { instance: data, refresh, refreshing };
};

export const usePaymentMethods = (isFocused?: boolean) => {
  const { data, refresh, refreshing } = useApi<T.PaymentMethodRead[] | null>(
    (user) => listPaymentMethods(user),
    isFocused
  );
  return { paymentMethods: data, refresh, refreshing };
};

export const usePaymentMethod = (
  paymentMethodId: string,
  isFocused?: boolean
) => {
  const { data, refresh, refreshing } = useApi<T.PaymentMethodRead | null>(
    (user) => readPaymentMethod(user, paymentMethodId),
    isFocused
  );
  return { paymentMethod: data, refresh, refreshing };
};

export const useAnnouncement = (
  os: string,
  appVersion: string,
  isFocused?: boolean
) => {
  const { data, refresh, refreshing } = useApi<T.AnnouncementRead | null>(
    (user) => readAnnouncement(user, os, appVersion),
    isFocused
  );
  return { announcement: data, refresh, refreshing };
};
