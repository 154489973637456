import * as React from "react";
import {
  Box,
  Button,
  Heading,
  Text,
  Image,
  ScrollView,
  HStack,
  Modal,
} from "native-base";
import UserBody from "../components/UserBody";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { useInstanceReview, useUser } from "../lib/hooks";
import { CommitmentsParamList } from "../navigation/types";
import * as T from "../lib/types";
import { RefreshControl } from "react-native";
import { useState } from "react";
import { reviewInstance } from "../lib/api";
import MediaGallery from "../components/MediaGallery";

const getHumanizedStatusSummary = (instance: T.InstanceRead) => {
  let summary;
  let color;
  if (instance.evidence_review_status === T.EvidenceReviewStatus.APPROVED) {
    summary = "Approved";
    color = "green.500";
  } else if (
    instance.evidence_review_status === T.EvidenceReviewStatus.DENIED
  ) {
    summary = "Denied";
    color = "red.500";
  } else if (
    instance.evidence_submission_status === T.EvidenceSubmissionStatus.SUBMITTED
  ) {
    summary = "Awaiting Review";
    color = "orange.500";
  } else if (instance.status === T.InstanceStatus.EXPIRED) {
    summary = "Missed";
    color = "red.500";
  } else {
    summary = "Awaiting Evidence";
    color = "orange.500";
  }
  return [summary, color];
};

const hasEvidence = (evidence: T.EvidenceFile[] | null) => {
  return evidence !== null && evidence.length > 0;
};

export default function InstanceScreen() {
  const navigation = useNavigation();
  const route =
    useRoute<RouteProp<CommitmentsParamList, "CommitmentDetailsScreen">>();
  const user = useUser();
  const { instance, refresh } = useInstanceReview(route.params.id);
  const [scrollRefreshing, setScrollRefreshing] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const handleRefresh = async () => {
    setScrollRefreshing(true);
    await refresh();
    setScrollRefreshing(false);
  };

  const handleApprove = async () => {
    if (user !== null && instance !== null) {
      setSubmitting(true);
      await reviewInstance(user, instance.id, T.EvidenceReviewStatus.APPROVED);
      setSubmitting(false);
      navigation.navigate("ReviewsScreen");
    }
  };

  const handleDeny = async () => {
    if (user !== null && instance !== null) {
      setSubmitting(true);
      await reviewInstance(user, instance.id, T.EvidenceReviewStatus.DENIED);
      setSubmitting(false);
      setOpen(false);
      navigation.navigate("ReviewsScreen");
    }
  };

  return (
    <UserBody h="100%">
      <ScrollView
        flex={1}
        refreshControl={
          <RefreshControl
            refreshing={scrollRefreshing}
            onRefresh={handleRefresh}
          />
        }
      >
        {instance !== null ? (
          <Box w="100%">
            <Heading size="md">{instance.title}</Heading>
            <Heading size="sm" color="gray.500">
              {`${new Date(instance.due_at).toDateString()} at ${new Date(
                instance.due_at
              ).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}`}
            </Heading>
            <Box my={2} borderBottomWidth="1px" borderColor="gray.300" />
            <Heading mt={2} size="sm">
              Evidence Required
            </Heading>
            <Text>{instance.evidence_description}</Text>

            {instance.evidence_text === null ? (
              <></>
            ) : (
              <>
                <Heading mt={2} size="sm">
                  Uploaded Text
                </Heading>
                <Text>{instance.evidence_text}</Text>
              </>
            )}

            {!hasEvidence(instance.evidence_photos) ? (
              <></>
            ) : (
              <>
                <Heading mt={2} size="sm">
                  Uploaded Photos
                </Heading>
                <MediaGallery
                  mediaFiles={instance.evidence_photos}
                  itemDimension={150}
                />
              </>
            )}

            {!hasEvidence(instance.evidence_videos) ? (
              <></>
            ) : (
              <>
                <Heading mt={2} size="sm">
                  Uploaded Videos
                </Heading>
                <MediaGallery
                  mediaFiles={instance.evidence_videos}
                  itemDimension={150}
                />
              </>
            )}
            <Box my={2} borderBottomWidth="1px" borderColor="gray.300" />
            <HStack space={2} justifyContent="center">
              <Button
                flex={1}
                bgColor="green.500"
                color="white"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                onPress={handleApprove}
              >
                {"Approve "}
              </Button>
              <Button
                flex={1}
                bgColor="red.500"
                color="white"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                onPress={() => setOpen(true)}
              >
                {"Deny "}
              </Button>
            </HStack>
            <Modal isOpen={isOpen} onClose={() => setOpen(false)}>
              <Modal.Content maxW={400}>
                <Modal.CloseButton />
                <Modal.Header>Are you sure you want to deny?</Modal.Header>
                <Modal.Body>{`Are you sure you want to deny, your friend might be punished as a result!`}</Modal.Body>
                <Modal.Footer>
                  <Button.Group space={2}>
                    <Button
                      onPress={() => setOpen(false)}
                      isDisabled={isSubmitting}
                      isLoading={isSubmitting}
                    >
                      No, cancel.
                    </Button>
                    <Button
                      onPress={handleDeny}
                      isDisabled={isSubmitting}
                      isLoading={isSubmitting}
                      bgColor="red.500"
                      color="white"
                    >
                      Yes, deny.
                    </Button>
                  </Button.Group>
                </Modal.Footer>
              </Modal.Content>
            </Modal>
          </Box>
        ) : (
          <></>
        )}
      </ScrollView>
    </UserBody>
  );
}
