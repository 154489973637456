import { useNavigation } from "@react-navigation/native";
import { Box, Button, Heading, HStack, Spacer } from "native-base";
import React from "react";

const GuestHeader: React.FC = () => {
  const navigation = useNavigation();

  return (
    <Box
      h="64px"
      marginLeft="auto"
      marginRight="auto"
      width={["100%", "100%", 768, 992]}
      justifyContent="center"
    >
      <HStack>
        <Heading
          size="xl"
          onPress={() =>
            navigation.navigate(
              "LandingScreen"
              // Platform.OS === "web" ? "LandingScreen" : "SignUpScreen"
            )
          }
        >
          {"Kommit "}
        </Heading>
        <Spacer />
        {/* <Button onPress={() => navigation.navigate("SignInScreen")}>
          {"Sign In "}
        </Button>
        <Box w={1} />
        <Button onPress={() => navigation.navigate("SignUpScreen")}>
          {"Sign Up "}
        </Button> */}
      </HStack>
    </Box>
  );
};

export default GuestHeader;
