import * as React from "react";
import {
  Box,
  Button,
  Heading,
  Link,
  ScrollView,
  Spinner,
  Text,
} from "native-base";
import { signOut } from "../lib/auth";
import UserBody from "../components/UserBody";
import { useUserInfo } from "../lib/hooks";
import { RefreshControl } from "react-native";
import { useIsFocused } from "@react-navigation/native";
import { useState } from "react";

export default function AccountScreen() {
  const isFocused = useIsFocused();
  const { userInfo, refresh, refreshing } = useUserInfo(isFocused);
  const [scrollRefreshing, setScrollRefreshing] = useState(false);

  const handleSignOut = () => {
    signOut().then(() => {
      console.log("signed out");
    });
  };

  const handleRefresh = async () => {
    setScrollRefreshing(true);
    await refresh();
    setScrollRefreshing(false);
  };

  return (
    <UserBody h="100%">
      <ScrollView
        flex={1}
        refreshControl={
          <RefreshControl
            refreshing={scrollRefreshing}
            onRefresh={handleRefresh}
          />
        }
      >
        {userInfo !== null ? (
          <>
            <Heading size={"sm"}>{"Email "}</Heading>
            <Text>{userInfo.email}</Text>
            <Heading size={"sm"} mt={2}>
              {"Policy Information"}
            </Heading>
            <Text>
              <Link
                href="https://www.iubenda.com/privacy-policy/49876814"
                isExternal
                isUnderlined
              >
                Privacy Policy
              </Link>{" "}
              and{" "}
              <Link
                href="https://www.iubenda.com/privacy-policy/49876814/cookie-policy"
                isExternal
                isUnderlined
              >
                Cookie Policy.
              </Link>{" "}
            </Text>
            <Box mt={2} borderBottomWidth="1px" borderColor="gray.300" />
          </>
        ) : (
          <Spinner />
        )}
        <Button mt={2} onPress={handleSignOut}>
          {"Sign Out "}
        </Button>
      </ScrollView>
    </UserBody>
  );
}
