import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { v4 as uuid } from "uuid";
import { Button, Text } from "native-base";
import React, { ReactElement } from "react";
import {
  defaultDaySelectorState,
  getDayString,
} from "../components/DaySelector";
import Gush, { CompletionEvent, ElementProps } from "../components/gush";
import UserBody from "../components/UserBody";
import {
  addEvidenceFromFile,
  addEvidenceFromFileUri,
  addEvidenceText,
  createCommitment,
} from "../lib/api";
import { useUser } from "../lib/hooks";
import { CommitmentAddFormState, MediaType, URIType } from "../lib/types";
import { CommitmentsParamList } from "../navigation/types";
import EvidenceAddChoice from "./EvidenceAdd/EvidenceAddChoice";
import EvidenceAddText from "./EvidenceAdd/EvidenceAddText";
import { useAnalytics } from "../components/AnalyticsProvider";

const getMimeTypeAndExtension = (dataUri: string) => {
  const mimeType = dataUri.split(":")[1].split(";")[0];
  const ext = mimeType.split("/")[1];
  return [mimeType, ext];
};

const getFile = async (uri: string) => {
  const blob = await (await fetch(uri)).blob();
  const [mimeType, ext] = getMimeTypeAndExtension(uri);
  const file = new File([blob], `${uuid()}.${ext}`, { type: mimeType });
  return file;
};

interface FileInfo {
  uri: string;
  uriType: URIType;
  type: "image" | "video";
}

interface EvidenceAddFormState {
  evidenceText: string | null;
  evidenceFileInfo: FileInfo | null;
}

const stages = ["EvidenceAddChoice", "EvidenceAddText"] as const;
type Stage = typeof stages[number];

const config: Record<
  Stage,
  | ((props: ElementProps<EvidenceAddFormState>) => JSX.Element)
  | React.FC<ElementProps<EvidenceAddFormState>>
> = {
  EvidenceAddChoice: EvidenceAddChoice,
  EvidenceAddText: EvidenceAddText,
};

const initialState = {
  evidenceText: null,
  evidenceFileInfo: null,
};

export const EvidenceAddScreen = () => {
  const user = useUser();
  const route =
    useRoute<RouteProp<CommitmentsParamList, "EvidenceAddScreen">>();
  const navigation = useNavigation();
  const analytics = useAnalytics();
  
  const handleCompletion = async (
    event: CompletionEvent<EvidenceAddFormState>
  ) => {
    if (user) {
      analytics.trackEvent("SUBMITTED_EVIDENCE");

      // If text
      console.log(event.state);
      if (event.state.evidenceText != null) {
        await addEvidenceText(user, route.params.id, event.state.evidenceText);
      }
      // If File from uri
      else if (
        event.state.evidenceFileInfo &&
        event.state.evidenceFileInfo.uriType === URIType.path
      ) {
        await addEvidenceFromFileUri(
          user,
          route.params.id,
          event.state.evidenceFileInfo.uri
        );
      }
      // If File from file
      else if (
        event.state.evidenceFileInfo &&
        event.state.evidenceFileInfo.uriType === URIType.uri
      ) {
        const file = await getFile(event.state.evidenceFileInfo.uri);
        await addEvidenceFromFile(user, route.params.id, file);
      }
      // If something wrong
      else {
        throw Error("woopsie");
      }
      navigation.navigate("InstanceScreen", {
        id: route.params.id,
      });
    }
  };

  return (
    <UserBody h="100%">
      <Gush
        config={config}
        initialStage={"EvidenceAddChoice"}
        initialState={initialState}
        onCompletion={handleCompletion}
      />
    </UserBody>
  );
};

export default EvidenceAddScreen;
