import { Box } from "native-base";
import React from "react";

interface Props {
  h?: number | string;
}

const UserBody: React.FC<Props> = ({ h, children }) => {
  return (
    <Box h={h} width="100%" padding={4}>
      {children}
    </Box>
  );
};

export default UserBody;
