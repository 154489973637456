import { useNavigation } from "@react-navigation/native";
import { Box, Heading, HStack, Spacer } from "native-base";
import React from "react";

const UnverifiedUserHeader: React.FC = () => {
  const navigation = useNavigation();

  return (
    <Box
      h="64px"
      marginLeft="auto"
      marginRight="auto"
      width={["100%", "100%", 768, 992]}
      justifyContent="center"
    >
      <HStack>
        <Heading
          size="xl"
          onPress={() =>
            navigation.navigate(
              "LandingScreen"
              // Platform.OS === "web" ? "LandingScreen" : "SignUpScreen"
            )
          }
        >
          {"Kommit "}
        </Heading>
        <Spacer />
      </HStack>
    </Box>
  );
};

export default UnverifiedUserHeader;
