import { useNavigation } from "@react-navigation/native";
import * as Localization from "expo-localization";
import { Button, Text } from "native-base";
import React, { ReactElement } from "react";
import {
  defaultDaySelectorState,
  getDayString,
} from "../components/DaySelector";
import Gush, { CompletionEvent, ElementProps } from "../components/gush";
import UserBody from "../components/UserBody";
import { createCommitment } from "../lib/api";
import { useUser } from "../lib/hooks";
import {
  CommitmentAddFormState,
  CommitmentConfigureFormState,
  Currency,
  EvidenceSubmissionWindowOption,
  PaymentMethodConfigureFormState,
  PunishmentConfigureFormState,
  ReviewerConfigureFormState,
  TimeUnit,
} from "../lib/types";
import CommitmentConfigure from "./CommitmentAdd/CommitmentConfigure";
import CommitmentConfirm from "./CommitmentAdd/CommitmentConfirm";
import PaymentMethodConfigure from "./CommitmentAdd/PaymentMethodConfigure";
import PunishmentConfigure from "./CommitmentAdd/PunishmentConfigure";
import ReviewerConfigure from "./CommitmentAdd/ReviewerConfigure";

const stages = [
  "CommitmentConfigure",
  "PunishmentConfigure",
  "ReviewerConfigure",
  "PaymentMethodConfigure",
  "CommitmentConfirm",
] as const;
type Stage = typeof stages[number];

const config: Record<
  Stage,
  | ((props: ElementProps<CommitmentAddFormState>) => JSX.Element)
  | React.FC<ElementProps<CommitmentAddFormState>>
> = {
  CommitmentConfigure: CommitmentConfigure,
  PunishmentConfigure: PunishmentConfigure,
  ReviewerConfigure: ReviewerConfigure,
  PaymentMethodConfigure: PaymentMethodConfigure,
  CommitmentConfirm: CommitmentConfirm,
};

const initalDateTime = new Date();

const commitmentConfigureFormInitalState: CommitmentConfigureFormState = {
  title: "",
  initialDeadlineDate: initalDateTime,
  initialDeadlineTime: initalDateTime,
  timezone: Localization.timezone,
  repeatValue: 1,
  repeatUnit: TimeUnit.WEEKS,
  repeatDays: {
    ...defaultDaySelectorState,
    [getDayString(initalDateTime)]: true,
  },
  evidenceDescription: "",
  evidenceSubmissionWindowOption:
    EvidenceSubmissionWindowOption.AFTER_PREVIOUS_DEADLINE,
  evidenceSubmissionWindowValue: undefined,
  evidenceSubmissionWindowUnit: undefined,
};

const punishmentConfigureFormInitialState: PunishmentConfigureFormState = {
  punishmentOnValue: 2,
  punishmentAmountUnit: Currency.GBP,
  punishmentAmountValue: 20,
  punishmentRecipient: "",
  skipAllowance: 5
};

const reviewerConfigureFormInitialState: ReviewerConfigureFormState = {
  reviewerEmail: "",
};

const paymentMethodConfigureFormInitialState: PaymentMethodConfigureFormState =
  {
    paymentMethod: "",
  };

const commitmentAddFormInitialState: CommitmentAddFormState = {
  CommitmentConfigure: commitmentConfigureFormInitalState,
  PunishmentConfigure: punishmentConfigureFormInitialState,
  ReviewerConfigure: reviewerConfigureFormInitialState,
  PaymentMethodConfigure: paymentMethodConfigureFormInitialState,
};

export const CommitmentAddScreen = () => {
  const user = useUser();
  const navigation = useNavigation();

  const handleCompletion = async (
    event: CompletionEvent<CommitmentAddFormState>
  ) => {
    if (user) {
      const result = await createCommitment(user, event.state);
      if (result.ok) {
        navigation.navigate("CommitmentsScreen");
      }
    }
  };

  return (
    <UserBody h="100%">
      <Gush
        config={config}
        initialStage={"CommitmentConfigure"}
        initialState={commitmentAddFormInitialState}
        onCompletion={handleCompletion}
      />
    </UserBody>
  );
};

export default CommitmentAddScreen;
