import React from "react";
import { UserTabParamList } from "./types";
import { Feather } from "@expo/vector-icons";

interface TabIconProps {
  name: keyof UserTabParamList;
  size?: number;
  style?: any;
}

const TabIcon: React.FC<TabIconProps> = ({ name, ...props }) => {
  switch (name) {
    case "Commitments":
      return <Feather name="target" {...props} />;
    case "Reviews":
      return <Feather name="search" {...props} />;
    case "Account":
      return <Feather name="user" {...props} />;
    case "Support":
      return <Feather name="help-circle" {...props} />;
  }
};

export default TabIcon;
