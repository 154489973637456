import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import * as React from "react";
import tabs from "../tabs/tabs";

import { UserTabParamList } from "../types";
import TabIcon from "../TabIcon";
import { fontFamily } from "../../theme";

const BottomTab = createBottomTabNavigator<UserTabParamList>();

const MobileNavigator: React.FC = () => {
  return (
    <BottomTab.Navigator
      initialRouteName="Commitments"
      tabBarOptions={{ labelStyle: { fontFamily } }}
    >
      {tabs.map((tab) => {
        return (
          <BottomTab.Screen
            name={tab.name}
            component={tab.component}
            options={{
              tabBarIcon: () => (
                <TabIcon
                  name={tab.name}
                  size={20}
                  style={{ marginBottom: -3 }}
                />
              ),
            }}
            key={tab.name}
          />
        );
      })}
    </BottomTab.Navigator>
  );
};

export default MobileNavigator;
