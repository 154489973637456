import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import * as Linking from "expo-linking";

import GuestHeader from "../components/GuestHeader";
import LandingScreen from "../screens/LandingScreen";
import ResetPasswordScreen from "../screens/ResetPasswordScreen";
import SignInScreen from "../screens/SignInScreen";
import SignUpScreen from "../screens/SignUpScreen";
import { GuestScreenParamList } from "./types";

export const GuestLinkingConfiguration = {
  prefixes: [Linking.makeUrl("/")],
  config: {
    screens: {
      LandingScreen: "landing",
      SignInScreen: "sign-in",
      SignUpScreen: "sign-up",
      ResetPasswordScreen: "reset-password",
    },
  },
};

const GuestStack = createStackNavigator<GuestScreenParamList>();

const GuestNavigator = () => {
  return (
    <GuestStack.Navigator
      initialRouteName={"LandingScreen"}
      screenOptions={{
        headerTitle: () => <GuestHeader />,
        headerLeft: () => null,
        cardStyle: { backgroundColor: "#fff" },
        animationEnabled: false,
      }}
    >
      <GuestStack.Screen name="LandingScreen" component={LandingScreen} />
      <GuestStack.Screen name="SignInScreen" component={SignInScreen} />
      <GuestStack.Screen name="SignUpScreen" component={SignUpScreen} />
      <GuestStack.Screen
        name="ResetPasswordScreen"
        component={ResetPasswordScreen}
      />
    </GuestStack.Navigator>
  );
};

export default GuestNavigator;
