import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Text,
  Heading,
  Input,
  ScrollView,
} from "native-base";
import { useForm, Controller } from "react-hook-form";
import { CommitmentAddFormState } from "../../lib/types";
import { ElementProps } from "../../components/gush";
import { useAnalytics } from "../../components/AnalyticsProvider";

const ReviewerConfigure: React.FC<ElementProps<CommitmentAddFormState>> = ({
  state,
  setState,
  stage,
  setStage,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const analytics = useAnalytics();
  
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onNext = async (data: any) => {
    setSubmitting(true);
    setState((prevState) => ({
      ...prevState,
      ReviewerConfigure: { ...prevState.ReviewerConfigure, ...data },
    }));
    setStage("PaymentMethodConfigure");
    setSubmitting(false);
  };

  return (
    <ScrollView key={stage} flex={1} px={1}>
      <Heading size="md">Set Reviewer</Heading>
      <Text mt={2}>
        {
          "Your reviewer decides whether or not you will be punished, so make sure it's someone you trust. "
        }
      </Text>
      <Box mt={2} borderBottomWidth="1px" borderColor="gray.300" />

      {/* Reviewer Email */}
      <FormControl mt={2} isRequired isInvalid={"reviewerEmail" in errors}>
        <FormControl.Label>{"Reviewer email address "}</FormControl.Label>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              type="email"
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
            />
          )}
          name="reviewerEmail"
          rules={{
            required: "Field is required",
          }}
          defaultValue={state.ReviewerConfigure.reviewerEmail}
        />
        <FormControl.ErrorMessage>
          {errors.reviewerEmail?.message}
        </FormControl.ErrorMessage>
      </FormControl>

      <Button
        mt={2}
        onPress={(data) => {
          analytics.trackEvent("SUBMITTED_REVIEWER_CONFIGURE_FORM");
          handleSubmit(onNext)(data);
        }}
        isDisabled={submitting}
        isLoading={submitting}
      >
        {"Next "}
      </Button>
    </ScrollView>
  );
};

export default ReviewerConfigure;
