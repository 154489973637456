import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Heading,
  Input,
  ScrollView,
  Select,
  TextArea,
  Text,
} from "native-base";
import { useForm, Controller } from "react-hook-form";
import DateTimePicker from "../../components/DateTimePicker";
import DaySelector from "../../components/DaySelector";
import { CommitmentAddFormState } from "../../lib/types";
import { ElementProps } from "../../components/gush";
import Examples from "../../components/Examples";
import { isPositiveInteger } from "../../lib/utils";
import { allTimezones } from "../../lib/tz";

const formDescription =
  "Describe your commitment and how often you want to do it. You'll also need to describe what evidence should be provided to prove to your reviewer that you successfully completed the commitment.";

const CommitmentConfigure: React.FC<ElementProps<CommitmentAddFormState>> = ({
  state,
  setState,
  stage,
  setStage,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const onNext = async (data: any) => {
    setSubmitting(true);
    setState((prevState) => ({
      ...prevState,
      CommitmentConfigure: { ...prevState.CommitmentConfigure, ...data },
    }));
    setStage("PunishmentConfigure");
    setSubmitting(false);
  };

  return (
    <ScrollView key={stage} flex={1} px={1}>
      <Heading size="md">Describe Commitment</Heading>
      <Text mt={2}>{formDescription}</Text>
      <Examples />
      <Box mt={2} borderBottomWidth="1px" borderColor="gray.300" />

      {/* Title */}
      <FormControl mt={2} isRequired isInvalid={"title" in errors}>
        <FormControl.Label>{"Title "}</FormControl.Label>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Input
              type="title"
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              ref={ref}
            />
          )}
          name="title"
          rules={{
            required: "Field is required",
          }}
          defaultValue={state.CommitmentConfigure.title}
        />
        <FormControl.ErrorMessage>
          {errors.title?.message}
        </FormControl.ErrorMessage>
      </FormControl>
      {/* Deadline Time */}
      <FormControl
        mt={2}
        isRequired
        isInvalid={"initialDeadlineTime" in errors}
      >
        <FormControl.Label>{"Deadline time "}</FormControl.Label>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <DateTimePicker type="time" value={value} onChange={onChange} />
          )}
          name="initialDeadlineTime"
          rules={{ required: "Field is required" }}
          defaultValue={state.CommitmentConfigure.initialDeadlineTime}
        />
        <FormControl.ErrorMessage>
          {errors.initialDeadlineTime?.message}
        </FormControl.ErrorMessage>
      </FormControl>
      <FormControl mt={2} isRequired isInvalid={"timezone" in errors}>
        <Controller
          control={control}
          render={({ field: { onChange, value, ref } }) => {
            return (
              <Select onValueChange={onChange} selectedValue={value} ref={ref}>
                {allTimezones.map((timezone) => <Select.Item label={timezone} value={timezone} key={timezone} />)}
              </Select>
            );
          }}
          name="timezone"
          rules={{ required: "Field is required" }}
          defaultValue={state.CommitmentConfigure.timezone}
        />
        <FormControl.ErrorMessage>
          {errors.timezone?.message}
        </FormControl.ErrorMessage>
      </FormControl>
      {/* Repeat every */}
      <FormControl mt={2} isRequired isInvalid={"repeatValue" in errors}>
        <FormControl.Label>{"Repeat every "}</FormControl.Label>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              keyboardType="numeric"
              onBlur={onBlur}
              onChangeText={onChange}
              value={String(value)}
            />
          )}
          name="repeatValue"
          rules={{
            required: "Field is required",
            validate: {
              integer: (value) =>
                isPositiveInteger(value)
                  ? true
                  : "Must be a whole number greater than 0",
            },
          }}
          defaultValue={state.CommitmentConfigure.repeatValue}
        />
        <FormControl.ErrorMessage>
          {errors.repeatValue?.message}
        </FormControl.ErrorMessage>
      </FormControl>
      <FormControl mt={2} isRequired isInvalid={"repeatUnit" in errors}>
        <Controller
          control={control}
          render={({ field: { onChange, value, ref } }) => {
            return (
              <Select onValueChange={onChange} selectedValue={value} ref={ref}>
                <Select.Item label="days " value="DAYS" />
                <Select.Item label="weeks " value="WEEKS" />
                <Select.Item label="months " value="MONTHS" />
                <Select.Item label="years " value="YEARS" />
              </Select>
            );
          }}
          name="repeatUnit"
          rules={{ required: "Field is required" }}
          defaultValue={state.CommitmentConfigure.repeatUnit}
        />
        <FormControl.ErrorMessage>
          {errors.repeatUnit?.message}
        </FormControl.ErrorMessage>
      </FormControl>
      {/* Repeat on */}
      {watch("repeatUnit", state.CommitmentConfigure.repeatUnit) === "WEEKS" ? (
        <FormControl mt={2} isRequired isInvalid={"repeatDays" in errors}>
          <FormControl.Label>{"Repeat on "}</FormControl.Label>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <DaySelector value={value} onChange={onChange} />
            )}
            name="repeatDays"
            rules={{ required: "Field is required" }}
            defaultValue={state.CommitmentConfigure.repeatDays}
          />
          <FormControl.ErrorMessage>
            {errors.repeatDays?.message}
          </FormControl.ErrorMessage>
        </FormControl>
      ) : (
        <></>
      )}
      {/* Evidence description */}
      <FormControl
        mt={2}
        isRequired
        isInvalid={"evidenceDescription" in errors}
      >
        <FormControl.Label>
          {"What evidence will you need to provide? "}
        </FormControl.Label>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextArea onBlur={onBlur} onChangeText={onChange} value={value} />
          )}
          name="evidenceDescription"
          rules={{ required: "Field is required" }}
          defaultValue={state.CommitmentConfigure.evidenceDescription}
        />
        <FormControl.ErrorMessage>
          {errors.evidenceDescription?.message}
        </FormControl.ErrorMessage>
      </FormControl>
      {/* Evidence Submission Window Option */}
      <FormControl
        mt={2}
        isRequired
        isInvalid={"evidenceSubmissionWindowOption" in errors}
      >
        <FormControl.Label>
          {"When should you be able to submit evidence? "}
        </FormControl.Label>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Select onValueChange={onChange} selectedValue={value}>
              <Select.Item
                label={"After the previous deadline has passed. "}
                value="AFTER_PREVIOUS_DEADLINE"
              />
              <Select.Item
                label={"On the day of the deadline. "}
                value="DAY_OF_DEADLINE"
              />
              <Select.Item
                label={"On the week of the deadline. "}
                value="WEEK_OF_DEADLINE"
              />
              <Select.Item
                label={"On the month of the deadline. "}
                value="MONTH_OF_DEADLINE"
              />
              <Select.Item
                label={"A specific amount of time before the deadline. "}
                value="TIME_DELTA"
              />
            </Select>
          )}
          name="evidenceSubmissionWindowOption"
          rules={{ required: "Field is required" }}
          defaultValue={
            state.CommitmentConfigure.evidenceSubmissionWindowOption
          }
        />
        <FormControl.ErrorMessage>
          {errors.evidenceDescription?.message}
        </FormControl.ErrorMessage>
      </FormControl>
      {/* Evidence Submission Window Value and Unit */}
      {watch("evidenceSubmissionWindowOption") === "TIME_DELTA" ? (
        <>
          <FormControl
            mt={2}
            isRequired
            isInvalid={"evidenceSubmissionWindowValue" in errors}
          >
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  keyboardType="numeric"
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={String(value)}
                />
              )}
              name="evidenceSubmissionWindowValue"
              rules={{
                required: "Field is required",
                validate: {
                  integer: (value) =>
                    isPositiveInteger(value)
                      ? true
                      : "Must be a whole number greater than 0",
                },
              }}
              defaultValue={
                state.CommitmentConfigure.evidenceSubmissionWindowValue
              }
            />
            <FormControl.ErrorMessage>
              {errors.evidenceSubmissionWindowValue?.message}
            </FormControl.ErrorMessage>
          </FormControl>
          <FormControl
            mt={2}
            isRequired
            isInvalid={"evidenceSubmissionWindowUnit" in errors}
          >
            <Controller
              control={control}
              render={({ field: { onChange, value, ref } }) => {
                return (
                  <Select
                    onValueChange={onChange}
                    selectedValue={value}
                    ref={ref}
                  >
                    <Select.Item label="days " value="DAYS" />
                    <Select.Item label="weeks " value="WEEKS" />
                    <Select.Item label="months " value="MONTHS" />
                    <Select.Item label="years " value="YEARS" />
                  </Select>
                );
              }}
              name="evidenceSubmissionWindowUnit"
              rules={{ required: "Field is required" }}
              defaultValue={
                state.CommitmentConfigure.evidenceSubmissionWindowUnit
              }
            />
            <FormControl.ErrorMessage>
              {errors.evidenceSubmissionWindowUnit?.message}
            </FormControl.ErrorMessage>
          </FormControl>
        </>
      ) : (
        <></>
      )}
      <Button
        mt={2}
        onPress={handleSubmit(onNext)}
        isDisabled={submitting}
        isLoading={submitting}
      >
        {"Next "}
      </Button>
    </ScrollView>
  );
};

export default CommitmentConfigure;
