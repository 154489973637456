import React, { createContext, useEffect, useState } from "react";
import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyDZ168xSkvXzuuN1pTPdBsWeMcrY8fjwro",
  authDomain: "kommit-4b727.firebaseapp.com",
  projectId: "kommit-4b727",
  storageBucket: "kommit-4b727.appspot.com",
  messagingSenderId: "504047386841",
  appId: "1:504047386841:web:3f583fcf1d56abe7313ec8",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const auth = firebase.auth();
if (__DEV__) {
  auth.useEmulator("http://localhost:9091");
}

export const UserContext = createContext<{ user: null | firebase.User }>({
  user: null,
});

interface Props {
  children: React.ReactNode;
}

const UserProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<{ user: firebase.User | null }>({
    user: null,
  });
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    auth.onIdTokenChanged((u) => {
      setUser({ user: u });
      setIsComplete(true);
    });
  }, []);

  if (isComplete) {
    return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
  } else {
    return null;
  }
};

export default UserProvider;
