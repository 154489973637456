import React, { createContext, useContext, useEffect } from "react";
import Plausible from "plausible-tracker";

const plausible = Plausible({
  domain: "kommit.to",
  trackLocalhost: false
});
export const AnalyticsContext = createContext(plausible);

interface Props {
  children: React.ReactNode;
}

const AnalyticsProvider: React.FC<Props> = ({ children }) => {
  useEffect(plausible.enableAutoPageviews, []);
  return (
    <AnalyticsContext.Provider value={plausible}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => useContext(AnalyticsContext);
export default AnalyticsProvider;
