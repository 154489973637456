import React from "react";

import { Center, Text, Link } from "native-base";

export default function PrivacyFooter() {
  return (
    <Center h="40px" borderTopColor="gray.300" borderTopWidth={1}>
      <Text>
        <Link href="https://www.iubenda.com/privacy-policy/49876814" isExternal>
          Privacy Policy
        </Link>{" "}
        and{" "}
        <Link
          href="https://www.iubenda.com/privacy-policy/49876814/cookie-policy"
          isExternal
        >
          Cookie Policy
        </Link>{" "}
      </Text>
    </Center>
  );
}
