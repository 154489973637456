import { Ionicons } from "@expo/vector-icons";
import { HStack, IconButton, Spacer, VStack } from "native-base";
import React, { useState } from "react";

import {
  View,
  ImageBackground,
  ImageSourcePropType,
  LayoutChangeEvent,
  ImageResizeMode,
} from "react-native";

interface NavigationButtonsProps {
  fileIndex: number;
  numFiles: number;
  onClose: () => void;
  onForwards: () => void;
  onBackwards: () => void;
}

const NavigationButtons: React.FC<NavigationButtonsProps> = ({
  fileIndex,
  numFiles,
  onClose,
  onForwards,
  onBackwards,
}) => {
  return (
    <VStack flex={1} backgroundColor="rgba(0,0,0,0)" h="100%">
      <HStack>
        <Spacer />
        <IconButton
          onPress={onClose}
          icon={<Ionicons name="close" size={25} color="white" />}
        />
      </HStack>
      <Spacer />
      <HStack>
        {fileIndex > 0 ? (
          <IconButton
            onPress={onBackwards}
            icon={<Ionicons name="arrow-back" size={25} color="white" />}
          />
        ) : (
          <></>
        )}
        <Spacer />
        {fileIndex < numFiles - 1 ? (
          <IconButton
            onPress={onForwards}
            icon={<Ionicons name="arrow-forward" size={25} color="white" />}
          />
        ) : (
          <></>
        )}
      </HStack>
    </VStack>
  );
};

interface ResponsiveImageProps {
  source: ImageSourcePropType;
  resizeMode: ImageResizeMode;
  fileIndex: number;
  numFiles: number;
  onClose: () => void;
  onForwards: () => void;
  onBackwards: () => void;
}

const ResponsiveImageBackground: React.FC<ResponsiveImageProps> = ({
  source,
  resizeMode,
  fileIndex,
  numFiles,
  onClose,
  onForwards,
  onBackwards,
}) => {
  const [imageSize, setImageSize] = useState<{
    height: number;
    width: number;
  } | null>(null);
  const [showImage, setShowImage] = useState(false);

  const handleLayoutChange = (e: LayoutChangeEvent) => {
    const { height, width } = e.nativeEvent.layout;
    setImageSize({ height: height - 1, width: width - 1 });
    setShowImage(true);
  };

  return (
    <View
      onLayout={handleLayoutChange}
      style={{
        backgroundColor: "black",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      {imageSize && showImage ? (
        <ImageBackground
          source={source}
          resizeMode={resizeMode}
          style={{ height: imageSize.height, width: imageSize.width }}
        >
          <NavigationButtons
            fileIndex={fileIndex}
            numFiles={numFiles}
            onClose={onClose}
            onForwards={onForwards}
            onBackwards={onBackwards}
          />
        </ImageBackground>
      ) : (
        <></>
      )}
    </View>
  );
};

export default ResponsiveImageBackground;
