import React, { useEffect, useRef, useState } from "react";
import { Platform, useWindowDimensions } from "react-native";
import Constants from "expo-constants";
import * as Notifications from "expo-notifications";
import * as Linking from "expo-linking";
import {
  getStateFromPath as getStateFromPathDefault,
  LinkingOptions,
} from "@react-navigation/native";

import MobileNavigator from "./devices/MobileNavigator";
import WebNavigator from "./devices/WebNavigator";
import { useUser } from "../lib/hooks";
import { createExpoPushToken } from "../lib/api";

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

export const UserLinkingConfiguration: LinkingOptions = {
  prefixes: [Linking.makeUrl("/")],
  config: {
    screens: {
      Commitments: {
        screens: {
          CommitmentsScreen: "commitments",
          CommitmentsAddScreen: "commitments-add",
          CommitmentDetailsScreen: "commitment",
          CommitmentUpdateScreen: "commitment-update",
          InstanceScreen: "instance",
          EvidenceAddScreen: "evidence-add",
        },
      },
      Reviews: {
        screens: {
          ReviewsScreen: "reviews",
          InstanceReviewScreen: "instance-review",
        },
      },
      Account: {
        screens: {
          AccountScreen: "account",
        },
      },
      Support: {
        screens: {
          SupportScreen: "support",
        },
      },
    },
  },
  async getInitialURL() {
    // First, you may want to do the default deep link handling
    // Check if app was opened from a deep link
    let url = await Linking.getInitialURL();

    if (url != null) {
      return url;
    }

    // Handle URL from expo push notifications
    const response = await Notifications.getLastNotificationResponseAsync();
    if (response !== null) {
      url = Linking.createURL(
        response.notification.request.content.data.url as string
      );
    } else {
      url = Linking.createURL("/");
    }

    return url;
  },
  subscribe(listener: any) {
    const onReceiveURL = ({ url }: { url: string }) => listener(url);

    // Listen to incoming links from deep linking
    Linking.addEventListener("url", onReceiveURL);

    // Listen to expo push notifications
    const subscription = Notifications.addNotificationResponseReceivedListener(
      (response) => {
        let url: string;
        if (response) {
          url = Linking.createURL(
            response.notification.request.content.data.url as string
          );
        } else {
          url = Linking.createURL("/");
        }

        // Any custom logic to see whether the URL needs to be handled
        //...

        // Let React Navigation handle the URL
        listener(url);
      }
    );

    return () => {
      // Clean up the event listeners
      Linking.removeEventListener("url", onReceiveURL);
      subscription.remove();
    };
  },
  getStateFromPath(path, config) {
    const defaultState = getStateFromPathDefault(path, config);
    // add first page to routes, then you will have a back btn
    if (
      defaultState &&
      defaultState.routes.length === 1 &&
      defaultState.routes[0].state &&
      defaultState.routes[0].state.routes.length == 1
    ) {
      let firstRouteName: string;
      switch (defaultState.routes[0].name) {
        case "Commitments":
          firstRouteName = "CommitmentsScreen";
          break;
        case "Reviews":
          firstRouteName = "ReviewsScreen";
          break;
        default:
          firstRouteName = "AccountScreen";
          break;
      }
      if (defaultState.routes[0].state.routes[0].name !== firstRouteName) {
        defaultState.routes[0].state.routes.unshift({ name: firstRouteName });
      }
    }
    return defaultState;
  },
};

const UserNavigator = () => {
  const user = useUser();
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (user && Platform.OS !== "web") {
      registerForPushNotificationsAsync().then((token) => {
        if (token) {
          createExpoPushToken(user, token);
        }
      });
    }
  }, [user]);

  if (width > 500) {
    return <WebNavigator />;
  } else {
    return <MobileNavigator />;
  }
};

async function registerForPushNotificationsAsync() {
  let token;
  if (Constants.isDevice) {
    const {
      status: existingStatus,
    } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== "granted") {
      alert("Failed to get push token for push notification!");
      return;
    }
    token = (await Notifications.getExpoPushTokenAsync()).data;
  } else {
    alert("Must use physical device for Push Notifications");
  }

  if (Platform.OS === "android") {
    Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#FF231F7C",
    });
  }

  return token;
}

export default UserNavigator;
