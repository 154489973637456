import { Button, Modal, Spinner } from "native-base";
import React, { useState } from "react";
import { skipInstance } from "../lib/api";
import { useCommitment, useUser } from "../lib/hooks";
import { InstanceRead } from "../lib/types";
import { calculateRemainingSkipAllowance } from "../lib/utils";

interface SkipModalProps {
  isOpen: boolean;
  onClose: () => void;
  afterSkip: () => void;
  instance: InstanceRead;
}

const SkipModal: React.FC<SkipModalProps> = ({
  isOpen,
  onClose,
  afterSkip,
  instance,
}) => {
  const user = useUser();
  const { commitment } = useCommitment(instance.commitment);
  const [submitting, setSubmitting] = useState(false);

  const handleSkip = async () => {
    setSubmitting(true);
    if (user !== null) {
      await skipInstance(user, instance.id);
      await afterSkip();
    }
    setSubmitting(false);
    onClose();
  };

  if (commitment !== null) {
    const skipsRemaining = calculateRemainingSkipAllowance(
      commitment,
      new Date(instance.due_at)
    );

    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <Modal.Content maxW={400}>
          <Modal.CloseButton />

          {skipsRemaining > 0 ? (
            <>
              <Modal.Header>Are you sure you want to skip?</Modal.Header>
              <Modal.Body>{`Are you sure you want to skip, you only have ${skipsRemaining} skips remaining. Please note, if you have recently updated or deleted your commitment, skipping will delay the changes by an extra occurence.`}</Modal.Body>

              <Modal.Footer>
                <Button.Group space={2}>
                  <Button
                    onPress={onClose}
                    isDisabled={submitting}
                    isLoading={submitting}
                  >
                    No, cancel.
                  </Button>
                  <Button
                    onPress={handleSkip}
                    isDisabled={submitting}
                    isLoading={submitting}
                    bgColor="red.500"
                    color="white"
                  >
                    Yes, skip.
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </>
          ) : (
            <>
              <Modal.Header>Uh oh!</Modal.Header>
              <Modal.Body>{`Sorry you have no skips remaining.`}</Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button
                    onPress={onClose}
                    isDisabled={submitting}
                    isLoading={submitting}
                  >
                    Damn!
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </>
          )}
        </Modal.Content>
      </Modal>
    );
  } else {
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <Modal.Content maxW={400}>
          <Modal.CloseButton />
          <Spinner />
        </Modal.Content>
      </Modal>
    );
  }
};

export default SkipModal;
