import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  Spinner,
  FlatList,
  Button,
  HStack,
  VStack,
} from "native-base";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import { RefreshControl, TouchableOpacity } from "react-native";

import UserBody from "../components/UserBody";
import { useCommitments } from "../lib/hooks";
import { CommitmentStatus } from "../lib/types";
import { commitmentIsDeleted, commitmentIsUpdated } from "../lib/utils";
import { useAnalytics } from "../components/AnalyticsProvider";

import { default as dayjs } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

export default function CommitmentsScreen() {
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const { commitments, refresh, refreshing } = useCommitments(isFocused);
  const [scrollRefreshing, setScrollRefreshing] = useState(false);
  const analytics = useAnalytics();

  const handleRefresh = async () => {
    setScrollRefreshing(true);
    await refresh();
    setScrollRefreshing(false);
  };

  return (
    <UserBody h="100%">
      {commitments !== null ? (
        <FlatList
          mt={-2}
          data={commitments}
          refreshControl={
            <RefreshControl
              refreshing={scrollRefreshing}
              onRefresh={handleRefresh}
            />
          }
          renderItem={({ item }) => {
            return (
              <Box
                py={2}
                borderBottomWidth="1px"
                borderColor="gray.300"
                key={item.id}
              >
                <TouchableOpacity
                  onPress={() => {
                    analytics.trackEvent("CLICKED_COMMITMENT_DETAILS");
                    navigation.navigate("CommitmentDetailsScreen", {
                      id: item.id,
                      entity_id: item.entity_id,
                    });
                  }}
                >
                  <HStack alignItems="center" space={2}>
                    <VStack flex={1}>
                      <Heading size="md" numberOfLines={2}>
                        {item.title}
                      </Heading>
                      {new Date(item.initial_deadline) < new Date() ? (
                        <Text>
                          {`started ${dayjs(
                            new Date(item.initial_deadline)
                          ).fromNow()}`}
                        </Text>
                      ) : (
                        <Text>
                          {`begins ${dayjs(
                            new Date(item.initial_deadline)
                          ).fromNow()}`}
                        </Text>
                      )}
                    </VStack>
                    {commitmentIsDeleted(item) ? (
                      <Text
                        p={2}
                        borderRadius={6}
                        borderWidth="1px"
                        borderColor="red.500"
                        color="red.500"
                      >
                        {"Deleted "}
                      </Text>
                    ) : (
                      <></>
                    )}
                  </HStack>
                </TouchableOpacity>
              </Box>
            );
          }}
          ListFooterComponent={() => (
            <Button
              mt={2}
              onPress={() => {
                analytics.trackEvent("CLICKED_ADD_COMMITMENT");
                navigation.navigate("CommitmentsAddScreen");
              }}
            >
              {"Add Commitment "}
            </Button>
          )}
        />
      ) : (
        <Spinner />
      )}
    </UserBody>
  );
}
