import * as React from "react";
import { Box, Button, Center, Heading, HStack, Link, Text } from "native-base";
import GuestBody from "../components/GuestBody";
import { sendEmailVerification, signOut } from "../lib/auth";
import { useContext, useEffect } from "react";
import { UserContext } from "../components/UserProvider";
import UserBody from "../components/UserBody";
import firebase from "firebase";

export default function VerifyEmailScreen() {
  const { user } = useContext(UserContext);
  console.log("inverify", user);

  const handleResendEmail = () => {
    if (user !== null) {
      sendEmailVerification(user).then(() => {
        console.log("resent email");
      });
    }
  };

  const handleRefresh = async () => {
    if (user) {
      await user.reload();
      if (!user.emailVerified) {
        alert(
          "Email not yet verified! Make sure you click the link in your email. If you cant find the email try resending it."
        );
      } else {
        await user.getIdToken(true);
      }
    }
  };

  return (
    <UserBody>
      <Center>
        <Box w={["100%", 480]}>
          <Heading size="md">Verify Your Email Address</Heading>
          <Text mt={2}>
            {
              "Please click the link sent to your email by no-reply@kommit.to to verify your email address."
            }
          </Text>
          <HStack mt={2}>
            <Button w="50%" onPress={handleRefresh}>
              {"Continue "}
            </Button>
            <Box w={1} />
            <Button w="50%" variant="outline" onPress={handleResendEmail}>
              {"Resend email "}
            </Button>
          </HStack>
          <Center>
            <Button mt={2} backgroundColor="white" onPress={signOut}>
              <Text>{"Need to sign out? "}</Text>
            </Button>
          </Center>
        </Box>
      </Center>
    </UserBody>
  );
}
