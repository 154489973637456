import { Input } from "native-base";
import React, { useRef, useState } from "react";
import { TextInput } from "react-native";
import DateTimePicker from "@react-native-community/datetimepicker";
import { padString } from "../lib/utils";

const dateToTimeString = (date: Date) => {
  const hours = String(date.getHours());
  const minutes = String(date.getMinutes());
  return `${padString(hours, 2)}:${padString(minutes, 2)}`;
};

interface MobileDateTimePickerProps {
  type: "date" | "time";
  value: Date;
  onChange: (value: any) => any;
}

const MobileDateTimePicker: React.FC<MobileDateTimePickerProps> = ({
  type,
  value,
  onChange,
}) => {
  const inputRef = useRef<TextInput>(null);
  const [valueString, setValueString] = useState(
    type == "date" ? value.toDateString() : dateToTimeString(value)
  );
  const [showPicker, setShowPicker] = useState(false);

  const handleChange = (event: any, value: any) => {
    setShowPicker(false);
    setValueString(
      type == "date" ? value.toDateString() : dateToTimeString(value)
    );
    onChange(value);
  };

  return (
    <>
      <Input
        ref={inputRef}
        value={valueString}
        onFocus={() => {
          if (inputRef !== null && inputRef.current !== null) {
            inputRef.current.blur();
          }
          setShowPicker(true);
        }}
        onChange={(e) => onChange(new Date(e.nativeEvent.text))}
      />
      {showPicker ? (
        <DateTimePicker
          value={value}
          mode={type}
          is24Hour={true}
          display="default"
          onChange={handleChange}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default MobileDateTimePicker;
