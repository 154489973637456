import React, { useState } from "react";
import { Button, ScrollView, Modal } from "native-base";
import { CommitmentAddFormState } from "../../lib/types";
import { ElementProps } from "../../components/gush";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/core";
import { CommitmentsParamList } from "../../navigation/types";
import { useUser } from "../../lib/hooks";
import { deleteCommitment } from "../../lib/api";

const CommitmentUpdateOrDelete: React.FC<ElementProps<CommitmentAddFormState>> =
  ({ stage, setStage, state }) => {
    const user = useUser();
    const [submitting, setSubmitting] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    console.log(modalOpen);
    const navigation = useNavigation();
    const route =
      useRoute<RouteProp<CommitmentsParamList, "CommitmentUpdateScreen">>();

    const handleDeletion = async () => {
      setSubmitting(true);
      if (user) {
        await deleteCommitment(user, route.params.id);
        setSubmitting(false);
        navigation.navigate("CommitmentsScreen");
      }
    };

    return (
      <ScrollView key={stage} flex={1} px={1}>
        <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
          <Modal.Content maxW={400}>
            <Modal.CloseButton />
            <Modal.Header>Are you a quitter?</Modal.Header>
            <Modal.Body>
              {`Deleting a commitment is not immediate. You will still need to complete ${state.PunishmentConfigure.punishmentOnValue} more occurrences before the commitment ends. Therefore, deleting a commitment won't help you avoid punishment!`}
            </Modal.Body>
            <Modal.Footer>
              <Button.Group space={2}>
                <Button
                  onPress={() => {
                    setModalOpen(false);
                  }}
                  isDisabled={submitting}
                  isLoading={submitting}
                >
                  No way, cancel.
                </Button>
                <Button
                  onPress={handleDeletion}
                  isDisabled={submitting}
                  isLoading={submitting}
                  bgColor="red.500"
                  color="white"
                >
                  Yes I am, delete.
                </Button>
              </Button.Group>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
        <Button
          mt={2}
          onPress={() => setStage("CommitmentConfigure")}
          isDisabled={submitting}
          isLoading={submitting}
        >
          {"Update "}
        </Button>
        <Button
          mt={2}
          onPress={() => setModalOpen(true)}
          isDisabled={submitting}
          isLoading={submitting}
          bgColor="red.500"
          color="white"
        >
          {"Delete "}
        </Button>
      </ScrollView>
    );
  };

export default CommitmentUpdateOrDelete;
