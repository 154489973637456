import * as React from "react";
import { Box, Heading, Text, Spinner, FlatList, ScrollView } from "native-base";
import UserBody from "../components/UserBody";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import { useInstanceReviews } from "../lib/hooks";
import { RefreshControl, TouchableOpacity } from "react-native";
import { useEffect, useState } from "react";

export default function ReviewsScreen() {
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const { instances, refresh, refreshing } = useInstanceReviews(isFocused);
  const [scrollRefreshing, setScrollRefreshing] = useState(false);

  const handleRefresh = async () => {
    setScrollRefreshing(true);
    await refresh();
    setScrollRefreshing(false);
  };

  if (instances !== null && instances.length > 0) {
    return (
      <UserBody h="100%">
        <FlatList
          mt={-2}
          data={instances}
          refreshControl={
            <RefreshControl
              refreshing={scrollRefreshing}
              onRefresh={handleRefresh}
            />
          }
          renderItem={({ item }) => {
            return (
              <Box
                py={2}
                borderBottomWidth="1px"
                borderColor="gray.300"
                key={item.id}
              >
                <TouchableOpacity
                  onPress={() =>
                    navigation.navigate("InstanceReviewScreen", {
                      id: item.id,
                    })
                  }
                >
                  <Box>
                    <Heading size="md">{item.title}</Heading>
                    <Heading size="sm" color="gray.500">
                      {new Date(item.due_at).toDateString()}
                    </Heading>
                  </Box>
                </TouchableOpacity>
              </Box>
            );
          }}
        />
      </UserBody>
    );
  } else if (instances !== null && instances.length === 0) {
    return (
      <UserBody h="100%">
        <ScrollView
          flex={1}
          refreshControl={
            <RefreshControl
              refreshing={scrollRefreshing}
              onRefresh={handleRefresh}
            />
          }
        >
          <Text>
            When your friends' evidence needs reviewed, it will show up here.
          </Text>
        </ScrollView>
      </UserBody>
    );
  } else {
    return (
      <UserBody>
        <Spinner />
      </UserBody>
    );
  }
}
