import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import React from "react";
import { defaultDaySelectorState } from "../components/DaySelector";
import Gush, { CompletionEvent, ElementProps } from "../components/gush";
import UserBody from "../components/UserBody";
import { updateCommitment } from "../lib/api";
import { useCommitment, useUser } from "../lib/hooks";
import {
  CommitmentAddFormState,
  CommitmentConfigureFormState,
  CommitmentRead,
  DayOfWeek,
  PaymentMethodConfigureFormState,
  PunishmentConfigureFormState,
  ReviewerConfigureFormState,
} from "../lib/types";
import { CommitmentsParamList } from "../navigation/types";
import CommitmentConfigureUpdate from "./CommitmentAdd/CommitmentConfigureUpdate";
import CommitmentUpdateConfirm from "./CommitmentAdd/CommitmentUpdateConfirm";
import CommitmentUpdateOrDelete from "./CommitmentAdd/CommitmentUpdateOrDelete";
import PaymentMethodConfigure from "./CommitmentAdd/PaymentMethodConfigure";
import PunishmentConfigure from "./CommitmentAdd/PunishmentConfigure";
import ReviewerConfigure from "./CommitmentAdd/ReviewerConfigure";

const stages = [
  "CommitmentUpdateOrDelete",
  "CommitmentConfigure",
  "PunishmentConfigure",
  "ReviewerConfigure",
  "PaymentMethodConfigure",
  "CommitmentConfirm",
] as const;
type Stage = typeof stages[number];

const config: Record<
  Stage,
  | ((props: ElementProps<CommitmentAddFormState>) => JSX.Element)
  | React.FC<ElementProps<CommitmentAddFormState>>
> = {
  CommitmentUpdateOrDelete: CommitmentUpdateOrDelete,
  CommitmentConfigure: CommitmentConfigureUpdate,
  PunishmentConfigure: PunishmentConfigure,
  ReviewerConfigure: ReviewerConfigure,
  PaymentMethodConfigure: PaymentMethodConfigure,
  CommitmentConfirm: CommitmentUpdateConfirm,
};

const getRepeatDaysObject = (repeatDays: DayOfWeek[] | null) => {
  let obj = defaultDaySelectorState;
  if (repeatDays) {
    repeatDays.forEach((day) => (obj[day] = true));
  }
  return obj;
};

const getFormStateFromCommitment = (commitment: CommitmentRead) => {
  const initialDeadlineTime = new Date(commitment.initial_deadline);
  initialDeadlineTime.setHours(parseInt(commitment.deadline_time.slice(0, 2)));
  initialDeadlineTime.setMinutes(parseInt(commitment.deadline_time.slice(2)));

  const commitmentConfigureFormInitalState: CommitmentConfigureFormState = {
    title: commitment.title,
    initialDeadlineDate: initialDeadlineTime,
    initialDeadlineTime: initialDeadlineTime,
    timezone: commitment.timezone,
    repeatValue: commitment.repeat_value,
    repeatUnit: commitment.repeat_unit,
    repeatDays: getRepeatDaysObject(commitment.repeat_days),
    evidenceDescription: commitment.evidence_description
      ? commitment.evidence_description
      : "",
    evidenceSubmissionWindowOption:
      commitment.evidence_submission_window_option,
    evidenceSubmissionWindowValue: commitment.evidence_submission_window_value
      ? commitment.evidence_submission_window_value
      : undefined,
    evidenceSubmissionWindowUnit: commitment.evidence_submission_window_unit
      ? commitment.evidence_submission_window_unit
      : undefined,
  };

  const punishmentConfigureFormInitialState: PunishmentConfigureFormState = {
    punishmentOnValue: commitment.punishment_on_value,
    punishmentAmountUnit: commitment.punishment_amount_unit,
    punishmentAmountValue: commitment.punishment_amount_value,
    punishmentRecipient: commitment.punishment_recipient,
    skipAllowance: commitment.skip_allowance,
  };

  const reviewerConfigureFormInitialState: ReviewerConfigureFormState = {
    reviewerEmail: commitment.reviewer_email,
  };

  const paymentMethodConfigureFormInitialState: PaymentMethodConfigureFormState =
    {
      paymentMethod: commitment.payment_method,
    };

  const commitmentAddFormInitialState: CommitmentAddFormState = {
    CommitmentConfigure: commitmentConfigureFormInitalState,
    PunishmentConfigure: punishmentConfigureFormInitialState,
    ReviewerConfigure: reviewerConfigureFormInitialState,
    PaymentMethodConfigure: paymentMethodConfigureFormInitialState,
  };

  return commitmentAddFormInitialState;
};

export const CommitmentUpdateScreen = () => {
  const user = useUser();
  const navigation = useNavigation();
  const route =
    useRoute<RouteProp<CommitmentsParamList, "CommitmentUpdateScreen">>();
  const { commitment, refreshing, refresh } = useCommitment(route.params.id);

  let commitmentAddFormInitialState;
  if (commitment) {
    commitmentAddFormInitialState = getFormStateFromCommitment(commitment);
  } else {
    commitmentAddFormInitialState = null;
  }

  const handleCompletion = async (
    event: CompletionEvent<CommitmentAddFormState>
  ) => {
    if (user) {
      const result = await updateCommitment(user, route.params.id, event.state);
      if (result.ok) {
        navigation.navigate("CommitmentsScreen");
      }
    }
  };

  if (commitmentAddFormInitialState) {
    return (
      <UserBody h="100%">
        <Gush
          config={config}
          initialStage={"CommitmentUpdateOrDelete"}
          initialState={commitmentAddFormInitialState}
          onCompletion={handleCompletion}
        />
      </UserBody>
    );
  } else {
    return <></>;
  }
};

export default CommitmentUpdateScreen;
