import React, { useState } from "react";
import {
  Box,
  Button,
  Text,
  FormControl,
  Heading,
  Input,
  ScrollView,
  Select,
  Link,
} from "native-base";
import { useForm, Controller } from "react-hook-form";
import { CommitmentAddFormState } from "../../lib/types";
import { ElementProps } from "../../components/gush";
import { isInteger, isPositiveInteger } from "../../lib/utils";
import { useAnalytics } from "../../components/AnalyticsProvider";

const formDescription =
  "If you fail to submit evidence on time or your reviewer doesn't think your evidence is valid, you will be punished. ";

const PunishmentConfigure: React.FC<ElementProps<CommitmentAddFormState>> = ({
  state,
  setState,
  stage,
  setStage,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const analytics = useAnalytics();
  
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onNext = async (data: any) => {
    setSubmitting(true);
    setState((prevState) => ({
      ...prevState,
      PunishmentConfigure: { ...prevState.PunishmentConfigure, ...data },
    }));
    setStage("ReviewerConfigure");
    setSubmitting(false);
  };

  return (
    <ScrollView key={stage} flex={1} px={1}>
      <Heading size="md">Set Punishment</Heading>
      <Text mt={2}>{formDescription}</Text>
      <Text mt={2}>
        {"All money that we receive from punishments is donated to "}
        <Link
          _text={{ color: "rgb(6, 182, 212)" }}
          href="https://funds.effectivealtruism.org/funds/global-development"
          isExternal
        >
          {"charity"}
        </Link>
        {". "}
      </Text>
      <Box mt={2} borderBottomWidth="1px" borderColor="gray.300" />
      {/* Punish On Value */}
      <FormControl mt={2} isRequired isInvalid={"punishmentOnValue" in errors}>
        <FormControl.Label>
          {"After how many consecutive failures should you be punished? "}
        </FormControl.Label>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Input
              keyboardType="numeric"
              onBlur={onBlur}
              onChangeText={onChange}
              value={String(value)}
              ref={ref}
            />
          )}
          name="punishmentOnValue"
          rules={{
            required: "Field is required",
            validate: {
              integer: (value) =>
                isPositiveInteger(value)
                  ? true
                  : "Must be a whole number greater than 0",
            },
          }}
          defaultValue={state.PunishmentConfigure.punishmentOnValue}
        />
        <FormControl.ErrorMessage>
          {errors.punishmentOnValue?.message}
        </FormControl.ErrorMessage>
      </FormControl>

      {/* Punishment Amount Value */}
      <FormControl
        mt={2}
        isRequired
        isInvalid={"punishmentAmountValue" in errors}
      >
        <FormControl.Label>
          {"How much should you be charged when punished? "}
        </FormControl.Label>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Input
              keyboardType="numeric"
              onBlur={onBlur}
              onChangeText={onChange}
              value={String(value)}
              ref={ref}
            />
          )}
          name="punishmentAmountValue"
          rules={{
            required: "Field is required",
            validate: {
              integer: (value) =>
                isPositiveInteger(value)
                  ? true
                  : "Must be a whole number greater than 0",
            },
          }}
          defaultValue={state.PunishmentConfigure.punishmentAmountValue}
        />
        <FormControl.ErrorMessage>
          {errors.punishmentAmountValue?.message}
        </FormControl.ErrorMessage>
      </FormControl>
      <FormControl
        mt={2}
        isRequired
        isInvalid={"punishmentAmountUnit" in errors}
      >
        <Controller
          control={control}
          render={({ field: { onChange, value, ref } }) => {
            return (
              <Select onValueChange={onChange} selectedValue={value} ref={ref}>
                <Select.Item label="GBP" value="GBP" />
                <Select.Item label="EUR " value="EUR" />
                <Select.Item label="USD " value="USD" />
              </Select>
            );
          }}
          name="punishmentAmountUnit"
          rules={{ required: "Field is required" }}
          defaultValue={state.PunishmentConfigure.punishmentAmountUnit}
        />
        <FormControl.ErrorMessage>
          {errors.repeatUnit?.message}
        </FormControl.ErrorMessage>
      </FormControl>

      {/* Skip Allowance */}
      <FormControl mt={2} isRequired isInvalid={"skipAllowance" in errors}>
        <FormControl.Label>
          {"How many skips should you be allowed per year? "}
        </FormControl.Label>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Input
              keyboardType="numeric"
              onBlur={onBlur}
              onChangeText={onChange}
              value={String(value)}
              ref={ref}
            />
          )}
          name="skipAllowance"
          rules={{
            required: "Field is required",
            validate: {
              integer: (value) =>
                isInteger(value)
                  ? true
                  : "Must be a whole number greater than or equal to 0",
            },
          }}
          defaultValue={state.PunishmentConfigure.skipAllowance}
        />
        <FormControl.ErrorMessage>
          {errors.skipAllowance?.message}
        </FormControl.ErrorMessage>
      </FormControl>

      <Button
        mt={2}
        onPress={(data) => {
          analytics.trackEvent("SUBMITTED_PUNISHMENT_CONFIGURE_FORM");
          handleSubmit(onNext)(data);
        }}
        isDisabled={submitting}
        isLoading={submitting}
      >
        {"Next "}
      </Button>
    </ScrollView>
  );
};

export default PunishmentConfigure;
