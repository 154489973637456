import React, { useState } from "react";
// import "./WebDateTimePicker.css";

const padString = (stringToPad: string, targetLength: number) => {
  const numLeadingZeros = targetLength - stringToPad.length;
  return "0".repeat(numLeadingZeros) + stringToPad;
};

const dateToDateString = (date: Date) => {
  const year = String(date.getFullYear());
  const month = String(date.getMonth() + 1);
  const day = String(date.getDate());
  return `${year}-${padString(month, 2)}-${padString(day, 2)}`;
};

const dateToTimeString = (date: Date) => {
  const hours = String(date.getHours());
  const minutes = String(date.getMinutes());
  return `${padString(hours, 2)}:${padString(minutes, 2)}`;
};

const dateStringToDate = (dateString: string) => {
  const year = dateString.substring(0, 4);
  const month = dateString.substring(5, 7);
  const day = dateString.substring(8, 10);
  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
};

const timeStringToDate = (timeString: string) => {
  const hours = timeString.substring(0, 2);
  const minutes = timeString.substring(3, 5);
  let date = new Date();
  date.setHours(parseInt(hours));
  date.setMinutes(parseInt(minutes));
  return date;
};

interface WebDateTimePickerProps {
  type: "date" | "time";
  value: Date;
  onChange: (value: any) => any;
}

const unfocusedStyle = {
  paddingLeft: "16px",
  paddingRight: "16px",
  borderRadius: "6px",
  borderWidth: "1px",
  borderColor: "#e5e5e5",
  borderStyle: "solid",
  fontSize: "13.3333px",
  fontFamily: "Arial",
  fontWeight: 400,
  height: "42px",
};

const focusedStyle = {
  paddingLeft: "16px",
  paddingRight: "16px",
  borderRadius: "6px",
  borderWidth: "1px",
  borderColor: "#22d3ee",
  borderStyle: "solid",
  fontSize: "13.3333px",
  fontFamily: "Arial",
  fontWeight: 400,
  height: "42px",
  outline: "none",
  boxShadow: "0 0 0 1px #22d3ee",
};

const WebDateTimePicker: React.FC<WebDateTimePickerProps> = ({
  type,
  value,
  onChange,
}) => {
  const [focused, setFocused] = useState(false);
  if (type === "date") {
    return (
      <input
        className="DateTimeInput"
        type={type}
        style={focused ? focusedStyle : unfocusedStyle}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        value={dateToDateString(value)}
        onChange={(e) => onChange(dateStringToDate(e.currentTarget.value))}
      />
    );
  } else {
    return (
      <input
        className="DateTimeInput"
        type={type}
        style={focused ? focusedStyle : unfocusedStyle}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        value={dateToTimeString(value)}
        onChange={(e) => onChange(timeStringToDate(e.currentTarget.value))}
      />
    );
  }
};

export default WebDateTimePicker;
