import * as FileSystem from "expo-file-system";
import firebase from "firebase";
import * as T from "./types";
import { getRepeatDaysArray, padString } from "./utils";

let URL: string;
if (__DEV__) {
  URL = "http://localhost:8080/v1/";
} else {
  URL = "https://api.kommit.to/v1/";
}

function delay(time: number) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export const get = (route: string, token: string) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(URL + route, requestOptions);
};

export const getWithUser = async (route: string, user: firebase.User) => {
  let response = await get(route, await user.getIdToken());
  if (response.status == 403) {
    await delay(3000);
    response = await get(route, await user.getIdToken(true));
  }
  return response;
};

export const post = (route: string, token: string, body: object) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };
  return fetch(URL + route, requestOptions);
};

export const postWithUser = async (
  route: string,
  user: firebase.User,
  body: object
) => {
  let response = await post(route, await user.getIdToken(), body);
  if (response.status == 403) {
    await delay(3000);
    response = await post(route, await user.getIdToken(true), body);
  }
  return response;
};

export const deletee = (route: string, token: string) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return fetch(URL + route, requestOptions);
};

export const deleteWithUser = async (route: string, user: firebase.User) => {
  let response = await deletee(route, await user.getIdToken());
  if (response.status == 403) {
    await delay(3000);
    response = await deletee(route, await user.getIdToken(true));
  }
  return response;
};

const postFile = (route: string, token: string, formData: FormData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  };
  return fetch(URL + route, requestOptions);
};

const mergeDates = (dateDate: Date, timeDate: Date) => {
  let updatedDate = new Date();
  updatedDate.setUTCDate(dateDate.getDate());
  updatedDate.setUTCHours(timeDate.getHours());
  updatedDate.setUTCMinutes(timeDate.getMinutes());
  return updatedDate;
};

const getEvidenceSubmissionValue = (
  evidenceSubmissionWindowOption: T.EvidenceSubmissionWindowOption,
  evidenceSubmissionValue: number | undefined
) => {
  if (
    evidenceSubmissionWindowOption ===
      T.EvidenceSubmissionWindowOption.TIME_DELTA &&
    evidenceSubmissionValue !== undefined
  ) {
    return evidenceSubmissionValue;
  } else {
    return null;
  }
};

const getEvidenceSubmissionUnit = (
  evidenceSubmissionWindowOption: T.EvidenceSubmissionWindowOption,
  evidenceSubmissionUnit: T.TimeUnit | undefined
) => {
  if (
    evidenceSubmissionWindowOption ===
      T.EvidenceSubmissionWindowOption.TIME_DELTA &&
    evidenceSubmissionUnit !== undefined
  ) {
    return evidenceSubmissionUnit;
  } else {
    return null;
  }
};

export const createCommitment = async (
  user: firebase.User,
  formState: T.CommitmentAddFormState
) => {
  // Build commitment object
  const {
    CommitmentConfigure,
    PunishmentConfigure,
    ReviewerConfigure,
    PaymentMethodConfigure,
  } = formState;

  const commitment: T.CommitmentCreate = {
    title: CommitmentConfigure.title,
    initial_deadline: mergeDates(
      CommitmentConfigure.initialDeadlineDate,
      CommitmentConfigure.initialDeadlineTime
    ),
    timezone: CommitmentConfigure.timezone,
    repeat_value: CommitmentConfigure.repeatValue,
    repeat_unit: CommitmentConfigure.repeatUnit,
    repeat_days: getRepeatDaysArray(
      CommitmentConfigure.repeatUnit,
      CommitmentConfigure.repeatDays
    ),
    evidence_description: CommitmentConfigure.evidenceDescription,
    evidence_submission_window_option:
      CommitmentConfigure.evidenceSubmissionWindowOption,
    evidence_submission_window_value: getEvidenceSubmissionValue(
      CommitmentConfigure.evidenceSubmissionWindowOption,
      CommitmentConfigure.evidenceSubmissionWindowValue
    ),
    evidence_submission_window_unit: getEvidenceSubmissionUnit(
      CommitmentConfigure.evidenceSubmissionWindowOption,
      CommitmentConfigure.evidenceSubmissionWindowUnit
    ),
    punishment_on_value: PunishmentConfigure.punishmentOnValue,
    punishment_recipient: PunishmentConfigure.punishmentRecipient,
    punishment_amount_value: PunishmentConfigure.punishmentAmountValue,
    punishment_amount_unit: PunishmentConfigure.punishmentAmountUnit,
    skip_allowance: PunishmentConfigure.skipAllowance,
    reviewer_email: ReviewerConfigure.reviewerEmail,
    payment_method: PaymentMethodConfigure.paymentMethod,
  };
  return await postWithUser("user/commitment", user, commitment);
};

export const getDeadlineTime = (initialDeadlineTime: Date) => {
  const hours = initialDeadlineTime.getHours();
  const minutes = initialDeadlineTime.getMinutes();
  return padString(String(hours), 2) + padString(String(minutes), 2);
};

export const updateCommitment = async (
  user: firebase.User,
  commitment_id: string,
  formState: T.CommitmentAddFormState
) => {
  // Build commitment object
  const {
    CommitmentConfigure,
    PunishmentConfigure,
    ReviewerConfigure,
    PaymentMethodConfigure,
  } = formState;

  const commitment: T.CommitmentUpdate = {
    title: CommitmentConfigure.title,
    deadline_time: getDeadlineTime(CommitmentConfigure.initialDeadlineTime),
    timezone: CommitmentConfigure.timezone,
    repeat_value: CommitmentConfigure.repeatValue,
    repeat_unit: CommitmentConfigure.repeatUnit,
    repeat_days: getRepeatDaysArray(
      CommitmentConfigure.repeatUnit,
      CommitmentConfigure.repeatDays
    ),
    evidence_description: CommitmentConfigure.evidenceDescription,
    evidence_submission_window_option:
      CommitmentConfigure.evidenceSubmissionWindowOption,
    evidence_submission_window_value: getEvidenceSubmissionValue(
      CommitmentConfigure.evidenceSubmissionWindowOption,
      CommitmentConfigure.evidenceSubmissionWindowValue
    ),
    evidence_submission_window_unit: getEvidenceSubmissionUnit(
      CommitmentConfigure.evidenceSubmissionWindowOption,
      CommitmentConfigure.evidenceSubmissionWindowUnit
    ),
    punishment_on_value: PunishmentConfigure.punishmentOnValue,
    punishment_recipient: PunishmentConfigure.punishmentRecipient,
    punishment_amount_value: PunishmentConfigure.punishmentAmountValue,
    punishment_amount_unit: PunishmentConfigure.punishmentAmountUnit,
    skip_allowance: PunishmentConfigure.skipAllowance,
    reviewer_email: ReviewerConfigure.reviewerEmail,
    payment_method: PaymentMethodConfigure.paymentMethod,
  };
  return await postWithUser(
    `user/commitment/${commitment_id}`,
    user,
    commitment
  );
};

export const listCommitments = async (
  user: firebase.User
): Promise<T.CommitmentRead[]> => {
  const response = await getWithUser("user/commitment", user);
  if (response.status === 200) {
    return (await response.json()).data;
  } else {
    throw Error("err");
  }
};

export const readCommitment = async (
  user: firebase.User,
  commitment_id: string
): Promise<T.CommitmentRead> => {
  const response = await getWithUser(`user/commitment/${commitment_id}`, user);
  if (response.status === 200) {
    return (await response.json()).data;
  } else {
    throw Error("err");
  }
};

export const deleteCommitment = async (
  user: firebase.User,
  commitment_id: string
) => {
  const response = await deleteWithUser(
    `user/commitment/${commitment_id}`,
    user
  );
  if (response.status === 200) {
    return;
  } else {
    throw Error("err");
  }
};

export const listCommitmentEntityInstances = async (
  user: firebase.User,
  commitment_entity_id: string
): Promise<T.InstanceRead[]> => {
  const response = await getWithUser(
    `user/commitment-entity/${commitment_entity_id}/instance`,
    user
  );
  if (response.status === 200) {
    return (await response.json()).data;
  } else {
    throw Error("err");
  }
};

export const readInstance = async (
  user: firebase.User,
  instance_id: string
): Promise<T.InstanceRead> => {
  const response = await getWithUser(`user/instance/${instance_id}`, user);
  if (response.status === 200) {
    return (await response.json()).data;
  } else {
    throw Error("err");
  }
};

export const addEvidenceText = async (
  user: firebase.User,
  instanceId: string,
  evidenceText: string
) => {
  // Build commitment object
  const response = await postWithUser(
    `user/instance/${instanceId}/evidence-text`,
    user,
    {
      evidence_text: evidenceText,
    }
  );
  if (response.status === 200) {
    return (await response.json()).data;
  } else {
    throw Error("err");
  }
};

export const skipInstance = async (user: firebase.User, instanceId: string) => {
  // Build commitment object
  const response = await postWithUser(
    `user/instance/${instanceId}/skip`,
    user,
    {}
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    throw Error("err");
  }
};

export const addEvidenceFromFile = async (
  user: firebase.User,
  instanceId: string,
  blob: File
) => {
  // Build commitment object
  const token = await user.getIdToken();
  let formData = new FormData();
  formData.append("file", blob);
  await postFile(`user/instance/${instanceId}/evidence-file`, token, formData);
};

export const addEvidenceFromFileUri = async (
  user: firebase.User,
  instanceId: string,
  fileUri: string
) => {
  // Build commitment object
  const token = await user.getIdToken();
  await FileSystem.uploadAsync(
    URL + `user/instance/${instanceId}/evidence-file`,
    fileUri,
    {
      headers: { Authorization: `Bearer ${token}` },
      httpMethod: "POST",
      uploadType: FileSystem.FileSystemUploadType.MULTIPART,
      fieldName: "file",
    }
  );
};

export const listInstanceReviews = async (
  user: firebase.User
): Promise<T.InstanceRead[]> => {
  const response = await getWithUser(`user/instance-review`, user);
  if (response.status === 200) {
    return (await response.json()).data;
  } else {
    throw Error("err");
  }
};

export const readInstanceReview = async (
  user: firebase.User,
  instance_id: string
): Promise<T.InstanceRead> => {
  const response = await getWithUser(
    `user/instance-review/${instance_id}`,
    user
  );
  if (response.status === 200) {
    return (await response.json()).data;
  } else {
    throw Error("err");
  }
};

export const reviewInstance = async (
  user: firebase.User,
  instance_id: string,
  evidence_review_status: T.EvidenceReviewStatus
): Promise<Response> => {
  const response = await postWithUser(
    `user/instance-review/${instance_id}`,
    user,
    {
      evidence_review_status,
    }
  );
  if (response.status === 200) {
    return response;
  } else {
    throw Error("err");
  }
};

export const readUser = async (user: firebase.User): Promise<T.UserRead> => {
  const response = await getWithUser(`user/user`, user);
  if (response.status === 200) {
    return (await response.json()).data;
  } else {
    throw Error("err");
  }
};

export const createExpoPushToken = async (
  user: firebase.User,
  expoPushToken: string
): Promise<string> => {
  const response = await postWithUser(`user/expo-push-token`, user, {
    expo_push_token: expoPushToken,
  });
  if (response.status === 200) {
    return (await response.json()).data;
  } else {
    throw Error("err");
  }
};

export const listPaymentMethods = async (
  user: firebase.User
): Promise<T.PaymentMethodRead[]> => {
  const response = await getWithUser(`user/payment-method`, user);
  if (response.status === 200) {
    return (await response.json()).data;
  } else {
    throw Error("err");
  }
};

export const readPaymentMethod = async (
  user: firebase.User,
  id: string
): Promise<T.PaymentMethodRead> => {
  const response = await getWithUser(`user/payment-method/${id}`, user);
  if (response.status === 200) {
    return (await response.json()).data;
  } else {
    throw Error("err");
  }
};

export const sendUserSupportMessage = async (
  user: firebase.User,
  subject: string,
  message: string
): Promise<void> => {
  const response = await postWithUser(`user/send-support-message`, user, {
    subject,
    message,
  });
  if (response.status === 200) {
    return;
  } else {
    throw Error("err");
  }
};

export const readAnnouncement = async (
  user: firebase.User,
  os: string,
  appVersion: string
): Promise<T.AnnouncementRead | null> => {
  const response = await getWithUser(
    `guest/announcement/${os}/${appVersion}`,
    user
  );
  if (response.status === 200) {
    return (await response.json()).data;
  } else {
    throw Error("err");
  }
};
