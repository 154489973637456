import { Ionicons } from "@expo/vector-icons";
import {
  FlatList,
  HStack,
  Spacer,
  VStack,
  Text,
  Button,
  Heading,
  Spinner,
} from "native-base";
import React, { useEffect } from "react";
import { TouchableOpacity } from "react-native";
import { ElementProps } from "../../../components/gush";
import { usePaymentMethods } from "../../../lib/hooks";
import { useAnalytics } from "../../../components/AnalyticsProvider";

const isExpired = (expMonth: number, expYear: number) => {
  const date = new Date();
  const currentMonth = date.getMonth() + 1;
  const currentYear = date.getFullYear();
  return expMonth < currentMonth && expYear <= currentYear;
};

const PaymentMethodSelect: React.FC<ElementProps<string>> = ({
  state,
  setState,
  setStage,
  onCompletion,
}) => {
  const { paymentMethods } = usePaymentMethods();
  const analytics = useAnalytics();
  
  useEffect(() => {
    if (state.length > 0) {
      onCompletion();
    }
  }, [state]);

  if (paymentMethods) {
    return (
      <FlatList
        data={paymentMethods}
        ListHeaderComponent={() => (
          <Heading size="md">Select Payment Method</Heading>
        )}
        renderItem={({ item }) => {
          const expired = isExpired(item.card.exp_month, item.card.exp_year);
          const expiresText = expired ? "Expired" : "Expires";
          const color = expired ? "red.500" : "black";
          return (
            <TouchableOpacity
              onPress={() => {
                analytics.trackEvent("SELECTED_PAYMENT_METHOD");
                setState(item.id);
              }}
              disabled={expired}
            >
              <HStack
                py={2}
                borderBottomWidth="1px"
                borderColor="gray.300"
                alignItems="center"
              >
                <Ionicons name="card-outline" size={30} />
                <Spacer />
                <VStack>
                  <Text>{`●●●●  ●●●●  ●●●●  ${item.card.last4}`}</Text>
                  <HStack>
                    <Text>{`${item.card.brand.toUpperCase()}    `}</Text>
                    <Spacer />
                    <Text
                      color={color}
                    >{`${expiresText} ${item.card.exp_month}/${item.card.exp_year}`}</Text>
                  </HStack>
                </VStack>
              </HStack>
            </TouchableOpacity>
          );
        }}
        ListFooterComponent={() => (
          <Button
            mt={2}
            onPress={() => {
              analytics.trackEvent("CLICKED_ADD_PAYMENT_METHOD");
              setStage("PaymentMethodCreate");
            }}
          >
            {"Add New Payment Method "}
          </Button>
        )}
      />
    );
  } else {
    return <Spinner />;
  }
};

export default PaymentMethodSelect;
