import * as React from "react";
import { RefreshControl } from "react-native";
import {
  RouteProp,
  useIsFocused,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import {
  Box,
  Button,
  Heading,
  Text,
  Image,
  ScrollView,
  Modal,
} from "native-base";

import UserBody from "../components/UserBody";
import { useInstance } from "../lib/hooks";
import { CommitmentsParamList } from "../navigation/types";
import * as T from "../lib/types";
import MediaGallery from "../components/MediaGallery";
import { getHumanizedStatusSummary } from "../lib/utils";
import { useState } from "react";
import SkipModal from "../components/SkipModal";
import { useAnalytics } from "../components/AnalyticsProvider";

const hasEvidence = (evidence: T.EvidenceFile[] | null) => {
  return evidence !== null && evidence.length > 0;
};

const StatusSummary: React.FC<{ instance: T.InstanceRead }> = ({
  instance,
}) => {
  const [status, color] = getHumanizedStatusSummary(instance);
  return <Text color={color}>{status}</Text>;
};

export default function InstanceScreen() {
  const navigation = useNavigation();
  const route = useRoute<RouteProp<CommitmentsParamList, "InstanceScreen">>();
  const isFocused = useIsFocused();
  const { instance, refresh, refreshing } = useInstance(
    route.params.id,
    isFocused
  );
  const [scrollRefreshing, setScrollRefreshing] = useState(false);
  const [skipModalOpen, setSkipModalOpen] = useState(false);
  const analytics = useAnalytics();

  const handleRefresh = async () => {
    setScrollRefreshing(true);
    await refresh();
    setScrollRefreshing(false);
  };

  return (
    <UserBody h="100%">
      <ScrollView
        flex={1}
        refreshControl={
          <RefreshControl
            refreshing={scrollRefreshing}
            onRefresh={handleRefresh}
          />
        }
      >
        {instance !== null ? (
          <Box w="100%">
            <Heading size="md">{instance.title}</Heading>
            <Heading size="sm" color="gray.500">
              {`${new Date(instance.due_at).toDateString()} at ${new Date(
                instance.due_at
              ).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}`}
            </Heading>
            <StatusSummary instance={instance} />
            <Box my={2} borderBottomWidth="1px" borderColor="gray.300" />
            <Heading mt={2} size="sm">
              Evidence Required
            </Heading>
            <Text>{instance.evidence_description}</Text>

            {instance.evidence_text === null ? (
              <></>
            ) : (
              <>
                <Heading mt={2} size="sm">
                  Uploaded Text
                </Heading>
                <Text>{instance.evidence_text}</Text>
              </>
            )}

            {!hasEvidence(instance.evidence_photos) ? (
              <></>
            ) : (
              <>
                <Heading mt={2} size="sm">
                  Uploaded Photos
                </Heading>
                <MediaGallery
                  mediaFiles={instance.evidence_photos}
                  itemDimension={150}
                />
              </>
            )}

            {!hasEvidence(instance.evidence_videos) ? (
              <></>
            ) : (
              <>
                <Heading mt={2} size="sm">
                  Uploaded Videos
                </Heading>
                <MediaGallery
                  mediaFiles={instance.evidence_videos}
                  itemDimension={150}
                />
              </>
            )}
            <Box mt={2} borderBottomWidth="1px" borderColor="gray.300" />
            {instance.status === T.InstanceStatus.VISIBLE &&
            instance.evidence_submission_status !==
              T.EvidenceSubmissionStatus.SKIPPED &&
            instance.evidence_review_status ===
              T.EvidenceReviewStatus.NOT_SUBMITTED ? (
              <>
                <Button
                  mt={2}
                  onPress={() => {
                    analytics.trackEvent("CLICKED_ADD_EVIDENCE");
                    navigation.navigate("EvidenceAddScreen", {
                      id: instance.id,
                    });
                  }}
                >
                  {"Add Evidence "}
                </Button>
                <Button
                  mt={2}
                  onPress={() => setSkipModalOpen(true)}
                  bgColor="red.500"
                  color="white"
                >
                  {"Skip deadline "}
                </Button>
              </>
            ) : (
              <></>
            )}
          </Box>
        ) : (
          <></>
        )}
        {instance !== null ? (
          <SkipModal
            isOpen={skipModalOpen}
            onClose={() => setSkipModalOpen(false)}
            afterSkip={handleRefresh}
            instance={instance}
          />
        ) : (
          <></>
        )}
      </ScrollView>
    </UserBody>
  );
}
