import * as React from "react";
import {
  Box,
  Button,
  Center,
  FormControl,
  Heading,
  Input,
  Text,
} from "native-base";
import GuestBody from "../components/GuestBody";
import { Link, useNavigation } from "@react-navigation/native";
import { sendEmailVerification, signInWithEmailAndPassword } from "../lib/auth";
import { useForm, Controller } from "react-hook-form";
import UserBody from "../components/UserBody";
import { ScrollView } from "react-native";
import PrivacyFooter from "../components/PrivacyFooter";
import { useAnalytics } from "../components/AnalyticsProvider";

interface SignInFormData {
  email: string;
  password: string;
}

export default function SignInScreen() {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const navigation = useNavigation();
  const analytics = useAnalytics();

  const onSubmit = (data: SignInFormData) => {
    signInWithEmailAndPassword(data.email, data.password)
      .then((userCredential) => {
        if (userCredential.user) {
          if (!userCredential.user.emailVerified) {
            sendEmailVerification(userCredential.user);
          }
        }
        console.log("signed in");
      })
      .catch((e) => {
        if (e.code === "auth/user-not-found") {
          setError("email", {
            type: "manual",
            message: "Email is not associated with an account.",
          });
        } else if (e.code === "auth/wrong-password") {
          setError("password", {
            type: "manual",
            message: "Password is incorrect.",
          });
        }
      });
  };

  return (
    <UserBody h="100%">
      <ScrollView>
        <Center>
          <Box w={["100%", 480]}>
            <Heading size="md">Sign In</Heading>

            {/* Email */}
            <FormControl mt={2} isRequired isInvalid={"email" in errors}>
              <FormControl.Label>{"Email "}</FormControl.Label>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    type="email"
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                  />
                )}
                name="email"
                rules={{
                  required: "Field is required",
                }}
                defaultValue=""
              />
              <FormControl.ErrorMessage>
                {errors.email?.message}
              </FormControl.ErrorMessage>
            </FormControl>

            {/* Password */}
            <FormControl mt={2} isRequired isInvalid={"password" in errors}>
              <FormControl.Label>{"Password "}</FormControl.Label>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    type="password"
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                  />
                )}
                name="password"
                rules={{ required: "Field is required" }}
                defaultValue=""
              />
              <FormControl.ErrorMessage>
                {errors.password?.message}
              </FormControl.ErrorMessage>
            </FormControl>

            <Button
              mt={2}
              onPress={(data) => {
                analytics.trackEvent("SUBMITTED_SIGN_IN_FORM");
                handleSubmit(onSubmit)(data);
              }}
            >
              {"Submit "}
            </Button>
            <Center>
              <Button
                mt={2}
                backgroundColor="white"
                onPress={() => navigation.navigate("ResetPasswordScreen")}
              >
                <Text>{"Forgot password? "}</Text>
              </Button>
            </Center>
          </Box>
        </Center>
      </ScrollView>
      <PrivacyFooter />
    </UserBody>
  );
}
