import { useElements, CardElement, useStripe } from "@stripe/react-stripe-js";
import { ConfirmCardSetupData } from "@stripe/stripe-js";
import { Box, Button, FormControl, Heading, Input } from "native-base";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ElementProps } from "../../../components/gush";
import { post } from "../../../lib/api";
import { useUser } from "../../../lib/hooks";
import { useAnalytics } from "../../../components/AnalyticsProvider";

const PaymentMethodCreate: React.FC<ElementProps<string>> = ({
  state,
  setState,
  onCompletion,
}) => {
  const user = useUser();
  const stripe = useStripe();
  const elements = useElements();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();
  const analytics = useAnalytics();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);

  useEffect(() => {
    const f = async () => {
      if (user) {
        const token = await user.getIdToken();
        const result = await post("user/create-setup-intent", token, {});
        const data = await result.json();
        setClientSecret(data.data.client_secret);
      }
    };
    f();
  }, []);

  useEffect(() => {
    if (state.length > 0) {
      onCompletion()
    }
  }, [state]);

  const onCreate = async (data: any) => {
    setIsSubmitting(true);
    if (user && stripe && elements && clientSecret) {
      const cardElement = elements.getElement(CardElement);
      if (cardElement) {
        const formData = {
          card: cardElement,
          billing_details: {
            name: data.name,
            email: user.email as string,
          },
        };
        const result = await stripe.confirmCardSetup(clientSecret, {
          payment_method: formData,
        });
        if (result.error) {
          setError("cardDetails", {
            type: "manual",
            message: result.error.message,
          });
        } else if (result.setupIntent && result.setupIntent.payment_method) {
          setState(result.setupIntent.payment_method);
        } else {
          setError("cardDetails", {
            type: "manual",
            message: "Oops something went wrong.",
          });
        }
      }
    }
    setIsSubmitting(false);
  };

  const unFocusedStyle = {
    borderColor: "#e5e5e5",
    boxShadow: "0 0 0 0 #FFFFFF",
  };

  const focusedStyle = {
    outline: "none",
    borderColor: "#22d3ee",
    boxShadow: "0 0 0 1px #22d3ee",
  };

  const [focused, setFocused] = useState(false);

  return (
    <Box>
      <Heading size="md">Add Payment Method</Heading>
      <FormControl mt={2} isRequired isInvalid={"name" in errors}>
        <FormControl.Label>{"Name On Card "}</FormControl.Label>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              type="name"
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
            />
          )}
          name="name"
          rules={{
            required: "Field is required",
          }}
          defaultValue=""
        />
        <FormControl.ErrorMessage>
          {errors.name?.message}
        </FormControl.ErrorMessage>
      </FormControl>

      <FormControl mt={2} isRequired isInvalid={"cardDetails" in errors}>
        <FormControl.Label>{"Card Details "}</FormControl.Label>
        <Box
          h="42px"
          borderWidth="1px"
          borderRadius="6px"
          px="16px"
          style={focused ? focusedStyle : unFocusedStyle}
        >
          <CardElement
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            options={{
              style: { base: { lineHeight: "42px", fontSize: "16px" } },
            }}
          />
        </Box>
        <FormControl.ErrorMessage>
          {errors.cardDetails?.message}
        </FormControl.ErrorMessage>
      </FormControl>

      <Button
        mt={2}
        onPress={(data) => {
          analytics.trackEvent("SUBMITTED_PAYMENT_METHOD_CREATE_FORM");
          handleSubmit(onCreate)(data);
        }}
        disabled={!elements || isSubmitting}
      >
        {"Submit "}
      </Button>
    </Box>
  );
};

export default PaymentMethodCreate;
