import { createStackNavigator } from "@react-navigation/stack";
import React from "react";

import CommitmentsScreen from "../../screens/CommitmentsScreen";
import CommitmentsAddScreen from "../../screens/CommitmentsAddScreen";
import CommitmentScreen from "../../screens/CommitmentDetailsScreen";
import InstanceScreen from "../../screens/InstanceScreen";
import { CommitmentsParamList } from "../types";
import EvidenceAddScreen from "../../screens/EvidenceAddScreen";
import CommitmentUpdateScreen from "../../screens/CommitmentUpdateScreen";
import { fontFamily } from "../../theme";

const CommitmentsStack = createStackNavigator<CommitmentsParamList>();

const CommitmentsNavigator: React.FC = () => {
  return (
    <CommitmentsStack.Navigator
      screenOptions={{
        cardStyle: { backgroundColor: "white" },
        headerTitleStyle: { fontFamily },
      }}
    >
      <CommitmentsStack.Screen
        name="CommitmentsScreen"
        component={CommitmentsScreen}
        options={{ headerTitle: "Commitments" }}
        // options={({ navigation }) =>
        //   getOptions(
        //     navigation,
        //     "Commitments",
        //     "CommitmentsScreen",
        //     "CommitmentsScreen",
        //     "CommitmentsAddScreen"
        //   )
        // }
      />
      <CommitmentsStack.Screen
        name="CommitmentsAddScreen"
        component={CommitmentsAddScreen}
        options={{ headerTitle: "Add Commitments" }}
        // options={({ navigation }) =>
        //   getOptions(
        //     navigation,
        //     "Add Commitment",
        //     "CommitmentsAddScreen",
        //     "CommitmentsScreen"
        //   )
        // }
      />
      <CommitmentsStack.Screen
        name="CommitmentDetailsScreen"
        component={CommitmentScreen}
        options={{ headerTitle: "Commitment" }}
        // options={({ navigation }) =>
        //   getOptions(
        //     navigation,
        //     "Commitment",
        //     "CommitmentDetailsScreen",
        //     "CommitmentsScreen"
        //   )
        // }
      />
      <CommitmentsStack.Screen
        name="CommitmentUpdateScreen"
        component={CommitmentUpdateScreen}
        options={{ headerTitle: "Update Commitment" }}
        // options={({ navigation }) =>
        //   getOptions(
        //     navigation,
        //     "Add Commitment",
        //     "CommitmentsAddScreen",
        //     "CommitmentsScreen"
        //   )
        // }
      />
      <CommitmentsStack.Screen
        name="InstanceScreen"
        component={InstanceScreen}
        options={{ headerTitle: "Deadline" }}
        // options={({ navigation }) =>
        //   getOptions(
        //     navigation,
        //     "Instance",
        //     "InstanceScreen",
        //     "CommitmentsScreen"
        //   )
        // }
      />
      <CommitmentsStack.Screen
        name="EvidenceAddScreen"
        component={EvidenceAddScreen}
        options={{ headerTitle: "Add Evidence" }}
        // options={({ navigation }) =>
        //   getOptions(
        //     navigation,
        //     "Add Evidence",
        //     "EvidenceAddCameraScreen",
        //     "CommitmentsScreen"
        //   )
        // }
      />
    </CommitmentsStack.Navigator>
  );
};

export default CommitmentsNavigator;
