/**
 * If you are not familiar with React Navigation, check out the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { NavigationContainer } from "@react-navigation/native";
import React, { useContext } from "react";

import { UserContext } from "../components/UserProvider";
import UserNavigator, { UserLinkingConfiguration } from "./UserNavigator";
import UnverifiedUserNavigator, {
  UnverifiedUserLinkingConfiguration,
} from "./UnverifiedUserNavigator";
import GuestNavigator, { GuestLinkingConfiguration } from "./GuestNavigator";
import AnnouncementModal from "../components/AnnouncementModal";

const Navigation = () => {
  const { user } = useContext(UserContext);

  if (user !== null && user.emailVerified) {
    return (
      <NavigationContainer
        linking={UserLinkingConfiguration}
        documentTitle={{ formatter: () => "Kommit" }}
      >
        <AnnouncementModal />
        <UserNavigator />
      </NavigationContainer>
    );
  } else if (user !== null && !user.emailVerified) {
    return (
      <NavigationContainer
        linking={UnverifiedUserLinkingConfiguration}
        documentTitle={{ formatter: () => "Kommit" }}
        key={String(user.emailVerified)}
      >
        <UnverifiedUserNavigator />
      </NavigationContainer>
    );
  } else {
    return (
      <NavigationContainer
        linking={GuestLinkingConfiguration}
        documentTitle={{ formatter: () => "Kommit" }}
      >
        <GuestNavigator />
      </NavigationContainer>
    );
  }
};

export default Navigation;
