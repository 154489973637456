import * as T from "./types";

export const getHumanizedStatusSummary = (instance: T.InstanceRead) => {
  let summary;
  let color;
  if (instance.evidence_review_status === T.EvidenceReviewStatus.APPROVED) {
    summary = "Approved";
    color = "green.500";
  } else if (
    instance.evidence_review_status === T.EvidenceReviewStatus.DENIED
  ) {
    summary = "Denied";
    color = "red.500";
  } else if (
    instance.evidence_submission_status === T.EvidenceSubmissionStatus.SUBMITTED
  ) {
    summary = "Awaiting Review";
    color = "orange.500";
  } else if (
    instance.evidence_submission_status === T.EvidenceSubmissionStatus.SKIPPED
  ) {
    summary = "Skipped";
    color = "orange.500";
  } else if (instance.status === T.InstanceStatus.EXPIRED) {
    summary = "Missed";
    color = "red.500";
  } else {
    summary = "Awaiting Evidence";
    color = "orange.500";
  }
  return [summary, color];
};

export const getOrdinal = (value: number) => {
  const j = value % 10;
  const k = value % 100;
  if (j == 1 && k != 11) {
    return "st";
  } else if (j == 2 && k != 12) {
    return "nd";
  } else if (j == 3 && k != 13) {
    return "rd";
  } else {
    return "th";
  }
};

export const getHumanizedRepeatDescription = (
  repeatValue: number,
  repeatUnit: T.TimeUnit,
  repeatDays: T.DayOfWeek[] | null,
  initalDeadlineDate: Date,
  initialDeadlineTime: Date,
  deadlineTime?: string,
  timezone?: string
) => {
  let value: string;
  let unit: string;
  let on: string;

  if (repeatValue === 1) {
    value = "";
    unit = repeatUnit.toLowerCase().slice(0, -1);
  } else {
    value = " " + String(repeatValue);
    unit = repeatUnit.toLowerCase().slice(0, -1);
  }

  if (repeatUnit === T.TimeUnit.DAYS) {
    on = "";
  } else if (repeatUnit == T.TimeUnit.WEEKS) {
    on = "";
    if (repeatDays !== null) {
      on = " on";
      repeatDays?.forEach((day, i) => {
        on = on + " " + day[0] + day.slice(1).toLowerCase();
        if (repeatDays && i < repeatDays.length - 2) {
          on += ",";
        } else if (repeatDays && i === repeatDays.length - 2) {
          on += " and";
        }
      });
    }
  } else if (repeatUnit == T.TimeUnit.MONTHS) {
    const date = new Date(initalDeadlineDate);
    const dom = date.getDate();
    on = ` on the ${dom}${getOrdinal(dom)}`;
  } else {
    const date = new Date(initalDeadlineDate);
    const dom = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    on = ` on the ${dom}${getOrdinal(dom)} of ${month}`;
  }

  let timeString: string;
  if (deadlineTime) {
    timeString = deadlineTime.slice(0, 2) + ":" + deadlineTime.slice(2);
  } else {
    timeString = new Date(initialDeadlineTime).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  if (timezone !== null) {
    return `Every${value} ${unit}${on} at ${timeString} (${timezone}).`;
  } else {
    return `Every${value} ${unit}${on} at ${timeString}.`
  }
    
};

export const DAYS: T.DayOfWeek[] = [
  T.DayOfWeek.MONDAY,
  T.DayOfWeek.TUESDAY,
  T.DayOfWeek.WEDNESDAY,
  T.DayOfWeek.THURSDAY,
  T.DayOfWeek.FRIDAY,
  T.DayOfWeek.SATURDAY,
  T.DayOfWeek.SUNDAY,
];

export const getRepeatDaysArray = (
  repeatUnit: T.TimeUnit,
  repeatDays: T.RepeatDays | undefined
) => {
  if (repeatUnit === T.TimeUnit.WEEKS && repeatDays !== undefined) {
    let result: T.DayOfWeek[] = [];
    DAYS.reduce((acc, curr) => {
      if (repeatDays[curr]) {
        acc.push(curr);
      }
      return acc;
    }, result);
    return result;
  } else {
    return null;
  }
};

export const getHumanizedPunishmentDescription = (
  punishmentOnValue: number,
  punishmentAmountValue: number,
  punishmentAmountUnit: T.Currency
) => {
  return `You will be charged ${punishmentAmountValue} ${punishmentAmountUnit} whenever you fail to complete your commitment ${punishmentOnValue} or more times in a row.`;
};

export const padString = (stringToPad: string, targetLength: number) => {
  const numLeadingZeros = targetLength - stringToPad.length;
  return "0".repeat(numLeadingZeros) + stringToPad;
};

export const isPositiveInteger = (value: string) => {
  return /^[1-9][0-9]*$/.test(value);
};

export const isInteger = (value: string) => {
  return /^[0-9][0-9]*$/.test(value);
};

export const commitmentIsDeleted = (commitment: T.CommitmentRead) => {
  return commitment.transition_at !== null && commitment.transition_to === null;
};

export const commitmentIsUpdated = (commitment: T.CommitmentRead) => {
  return commitment.transition_at !== null && commitment.transition_to !== null;
};

export const calculateSkipYear = (commitment: T.CommitmentRead, date: Date) => {
  const initDate = new Date(commitment.initial_deadline);
  const diffMs = date.getTime() - initDate.getTime();
  const ageDate = new Date(diffMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const calculateRemainingSkipAllowance = (
  commitment: T.CommitmentRead,
  date: Date
) => {
  const year = String(calculateSkipYear(commitment, date));
  if (year in commitment.skip_count_by_year) {
    return commitment.skip_allowance - commitment.skip_count_by_year[year];
  } else {
    return commitment.skip_allowance;
  }
};

export const nowOrInitialDeadline = (commitment: T.CommitmentRead) => {
  const now = new Date();
  const initialDeadline = new Date(commitment.initial_deadline);
  if (now > initialDeadline) {
    return now;
  } else {
    return initialDeadline;
  }
};
