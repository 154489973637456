import firebase from "firebase";

// Set API URL
let URL: string;
if (__DEV__) {
  URL = "http://localhost:8080/v1/";
} else {
  URL = "https://api.kommit.to/v1/";
}

// Set auth
const auth = firebase.auth();
if (__DEV__) {
  auth.useEmulator("http://localhost:9091")
}


export const signUpWithEmailAndPassword = async (
  email: string,
  password: string,
  timezone: string
) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password, timezone }),
  };
  const response = await fetch(URL + "guest/user", requestOptions);
  if (response.status === 200) {
    return (await response.json()).data;
  } else {
    const error = (await response.json()).error;
    throw Error(error.code);
  }
};

export const signInWithEmailAndPassword = (email: string, password: string) => {
  return auth.signInWithEmailAndPassword(email, password);
};

export const signOut = () => {
  return auth.signOut();
};

export const sendEmailVerification = (user: firebase.User) => {
  return user.sendEmailVerification();
};

export const sendPasswordReset = (email: string) => {
  return auth.sendPasswordResetEmail(email);
};
