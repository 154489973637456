import { createStackNavigator } from "@react-navigation/stack";
import React from "react";

import AccountScreen from "../../screens/AccountScreen";
import { fontFamily } from "../../theme";
import { AccountParamList } from "../types";

const AccountStack = createStackNavigator<AccountParamList>();

const AccountNavigator: React.FC = () => {
  return (
    <AccountStack.Navigator
      screenOptions={{
        cardStyle: { backgroundColor: "white" },
        headerTitleStyle: { fontFamily },
      }}
    >
      <AccountStack.Screen
        name="AccountScreen"
        component={AccountScreen}
        options={{ headerTitle: "Account" }}
      />
    </AccountStack.Navigator>
  );
};

export default AccountNavigator;
