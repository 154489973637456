import { Box } from "native-base";
import React from "react";
import Gush, { CompletionEvent, ElementProps } from "../../components/gush";
import UserBody from "../../components/UserBody";
import { CommitmentAddFormState } from "../../lib/types";
import PaymentMethodCreate from "./PaymentMethod/PaymentMethodCreate";
import PaymentMethodSelect from "./PaymentMethod/PaymentMethodSelect";

const stages = ["PaymentMethodSelect", "PaymentMethodCreate"] as const;
type Stage = typeof stages[number];

const config: Record<
  Stage,
  | ((props: ElementProps<string>) => JSX.Element)
  | React.FC<ElementProps<string>>
> = {
  PaymentMethodSelect: PaymentMethodSelect,
  PaymentMethodCreate: PaymentMethodCreate,
};

const initialPaymentMethod = "";

export const PaymentMethodConfigure: React.FC<
  ElementProps<CommitmentAddFormState>
> = ({ setState, setStage, informParentOfChild, parentGoBack }) => {
  
  const handlePaymentMethodCompletion = (event: CompletionEvent<string>) => {
    console.log(event)
    setState((prevState) => ({
      ...prevState,
      PaymentMethodConfigure: {
        ...prevState.PaymentMethodConfigure,
        paymentMethod: event.state,
      },
    }));
    setStage("CommitmentConfirm");
  };

  return (
    <Box h="100%">
      <Gush
        config={config}
        initialStage={"PaymentMethodSelect"}
        initialState={initialPaymentMethod}
        onCompletion={handlePaymentMethodCompletion}
        informParentOfChild={informParentOfChild}
        parentGoBack={parentGoBack}
      />
    </Box>
  );
};

export default PaymentMethodConfigure;
