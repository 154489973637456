import { createStackNavigator } from "@react-navigation/stack";
import React from "react";

import ReviewsScreen from "../../screens/ReviewsScreen";
import InstanceReviewScreen from "../../screens/InstanceReviewScreen";
import { ReviewsParamList } from "../types";
import { fontFamily } from "../../theme";

const ReviewsStack = createStackNavigator<ReviewsParamList>();

const ReviewsNavigator: React.FC = () => {
  return (
    <ReviewsStack.Navigator
      screenOptions={{
        cardStyle: { backgroundColor: "white" },
        headerTitleStyle: { fontFamily },
      }}
    >
      <ReviewsStack.Screen
        name="ReviewsScreen"
        component={ReviewsScreen}
        options={{ headerTitle: "Reviews" }}
      />
      <ReviewsStack.Screen
        name="InstanceReviewScreen"
        component={InstanceReviewScreen}
        options={{ headerTitle: "Review Commitment" }}
        // options={({ navigation }) =>
        //   getOptions(
        //     navigation,
        //     "Review Instance",
        //     "InstanceReviewScreen",
        //     "ReviewsScreen"
        //   )
        // }
      />
    </ReviewsStack.Navigator>
  );
};

export default ReviewsNavigator;
