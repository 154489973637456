import { useNavigation, useRoute, RouteProp } from "@react-navigation/core";
import { Platform } from "@unimodules/react-native-adapter";
import { v4 as uuid } from "uuid";
import { Box, Button } from "native-base";
import * as ImagePicker from "expo-image-picker";
import React, { useContext, useState } from "react";
import MediaGallery from "../../components/MediaGallery";
import UserBody from "../../components/UserBody";
import { UserContext } from "../../components/UserProvider";
import { addEvidenceFromFileUri, addEvidenceFromFile } from "../../lib/api";
import { CommitmentsParamList } from "../../navigation/types";
import { ElementProps } from "../../components/gush";
import { EvidenceAddFormState, MediaType, URIType } from "../../lib/types";

const getTypeFromDataURI = (dataURI: string): MediaType => {
  return dataURI.split(":")[1].split("/")[0] as MediaType;
};

const getTypes = ({ uri, type }: { uri: string; type?: string }) => {
  if (type) {
    return { uriType: URIType.path, type: type as MediaType };
  } else {
    return { uriType: URIType.uri, type: getTypeFromDataURI(uri) };
  }
};

const EvidenceAddChoice: React.FC<ElementProps<EvidenceAddFormState>> = ({
  state,
  setState,
  stage,
  setStage,
  onCompletion,
}) => {
  const [submitting, setSubmitting] = useState(false);

  // permissions
  React.useEffect(() => {
    (async () => {
      if (Platform.OS !== "web") {
        const { status } =
          await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== "granted") {
          alert("Sorry, we need camera roll permissions to make this work!");
        }
      }
    })();
  }, []);

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      quality: 0.7,
    });
    if (!result.cancelled) {
      setState({
        evidenceFileInfo: { ...getTypes(result), uri: result.uri },
        evidenceText: null,
      });
    }
  };

  const captureImage = async () => {
    let result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 0.7,
    });
    if (!result.cancelled) {
      setState({
        evidenceFileInfo: { ...getTypes(result), uri: result.uri },
        evidenceText: null,
      });
    }
  };

  const captureVideo = async () => {
    let result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Videos,
      quality: 0.7,
    });
    if (!result.cancelled) {
      setState({
        evidenceFileInfo: { ...getTypes(result), uri: result.uri },
        evidenceText: null,
      });
    }
  };

  const submitFile = async () => {
    setSubmitting(true);
    await onCompletion();
  };

  if (state.evidenceFileInfo !== null) {
    return (
      <Box>
        {state.evidenceFileInfo.type === "image" ? (
          <MediaGallery
            mediaFiles={[
              {
                url: state.evidenceFileInfo.uri,
                mime_type: "image",
                filename: "",
                caption: "",
              },
            ]}
            itemDimension={300}
          />
        ) : (
          <MediaGallery
            mediaFiles={[
              {
                url: state.evidenceFileInfo.uri,
                mime_type: "video",
                filename: "",
                caption: "",
              },
            ]}
            itemDimension={300}
          />
        )}
        <Button
          mt={2}
          onPress={() =>
            setState({ evidenceFileInfo: null, evidenceText: null })
          }
        >
          {"Remove File "}
        </Button>
        <Button
          mt={2}
          onPress={submitFile}
          isDisabled={submitting}
          isLoading={submitting}
        >
          {"Submit "}
        </Button>
      </Box>
    );
  } else {
    return (
      <Box>
        <Button mt={2} onPress={() => setStage("EvidenceAddText")}>
          {"Add Text "}
        </Button>
        {Platform.OS !== "web" ? (
          <>
            <Button mt={2} onPress={captureImage}>
              {"Capture Image "}
            </Button>
            <Button mt={2} onPress={captureVideo}>
              {"Capture Video "}
            </Button>
          </>
        ) : (
          <></>
        )}
        <Button mt={2} onPress={pickImage}>
          {"Upload from File "}
        </Button>
      </Box>
    );
  }
};

export default EvidenceAddChoice;
