import React, { useState } from "react";
import { Box, Text, VStack } from "native-base";
import { Ionicons } from "@expo/vector-icons";
import { TouchableOpacity } from "react-native-gesture-handler";

const Examples: React.FC = () => {
  const [open, setOpen] = useState(false);
  return (
    <Box mt={"2"}>
      <TouchableOpacity onPress={() => setOpen((v) => !v)}>
        <Text fontWeight={500}>
          <Ionicons name={open ? "chevron-down" : "chevron-forward"} />
          {" Examples "}
        </Text>
      </TouchableOpacity>
      {open ? (
        <VStack mt={2} space={2} borderWidth="1px" borderRadius="6px" p={2} borderColor="gray.300">
          <Box>
            <Text fontSize="sm">
              <Text fontSize="sm" fontWeight={500}>{"- Title: "}</Text>
              {"Go for a run everyday. "}
            </Text>
            <Text fontSize="sm">
              <Text fontSize="sm" fontWeight={500}>{"  Evidence: "}</Text>
              {"A video of myself running. "}
            </Text>
          </Box>
          <Box>
            <Text fontSize="sm">
              <Text fontSize="sm" fontWeight={500}>{"- Title: "}</Text>
              {"Study Korean on weekdays. "}
            </Text>
            <Text fontSize="sm">
              <Text fontSize="sm" fontWeight={500}>{"  Evidence: "}</Text>
              {"A screenshot of duolingo showing that I studied. "}
            </Text>
          </Box>
          <Box>
            <Text fontSize="sm">
              <Text fontSize="sm" fontWeight={500}>{"- Title: "}</Text>
              {"Write a blog post every month. "}
            </Text>
            <Text fontSize="sm">
              <Text fontSize="sm" fontWeight={500}>{"  Evidence: "}</Text>
              {"A link to the blog post. "}
            </Text>
          </Box>
        </VStack>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default Examples;
